import AliyunOss from './AliyunOss'
import { message } from 'ant-design-vue'
import store from '../store'
import { generateUUID } from '@/utils/index'
import axios from 'axios'

export default {
  // 上传学生作答媒体文件
  // putBuffer: async(bufferArr, id, timeout = 60000) => {
  /**
   * 上传学生作答媒体文件
   * @param bufferArr 文件
   * @param id id
   * @param timeout 超时时间，默认 60000 毫秒，即 60 秒
   * @returns {Promise<{res: {status: number}, url: string}|*>}
   */
  uploadMediaFile: async(bufferArr, id, timeout = 60000) => {
    // 是否弱网络
    const isWeakNetwork = sessionStorage.getItem('isWeakNetwork') || false
    // const filePath = `answer/${id}.wav`
    const userId = store.getters.userId
    const filePath = userId.substring(0, 4) + '/' + generateUUID() + '.wav'
    // 特殊学校，直接使用分片上传
    if (process.env.VUE_APP_MULTIPART_SCHOOL && JSON.parse(process.env.VUE_APP_MULTIPART_SCHOOL)[store.getters.userInfo.schoolId]) {
      const data = JSON.parse(process.env.VUE_APP_MULTIPART_SCHOOL)[store.getters.userInfo.schoolId]
      // 如果是异步上传，直接返回
      if (data.asyncUpload) {
        return {
          res: { status: 200 },
          url: ''
        }
      }
      try {
        return await AliyunOss.multipartUploadFile(filePath, bufferArr, 'answerMediaBucketName', data.timeout, data.parallel, data.partSize)
      } catch (e) {
        message.error('上传失败请检查网络设置')
        return e
      }
    }
    // 如果是弱网络，使用分片上传，否则使用完整上传
    if (isWeakNetwork) {
      try {
        return await AliyunOss.multipartUploadFile(filePath, bufferArr, 'answerMediaBucketName')
      } catch (e) {
        message.error('上传失败请检查网络设置')
        return e
      }
    }
    try {
      return await AliyunOss.putMediaFile(filePath, bufferArr, 'answerMediaBucketName')
    } catch (e) {
      console.log('完整上传文件失败，尝试使用分片上传')
      sessionStorage.setItem('isWeakNetwork', true)
      try {
        return await AliyunOss.multipartUploadFile(filePath, bufferArr, 'answerMediaBucketName')
      } catch (e) {
        message.error('上传失败请检查网络设置')
        return e
      }
    }
  },
  /**
   * 上传学生作答 info 数据
   * @param info info 数据
   * @param timeout 超时时间
   * @returns {Promise<*>}
   * 该存储桶内的文件 ObjectKey 规则如下: userId  +  "/" + 13位的毫秒时间戳 + ".info"
   * 例:  ce47c56df7ab4d09a3ab10ba0d935f74/1634288564397.info
   */
  uploadInfoFile: async(info, timeout = 60000) => {
    const userId = store.getters.userId
    const filePath = userId + '/' + new Date().getTime() + '.info'
    try {
      return await AliyunOss.putInfoFile(filePath, JSON.stringify(info), 'answerInfoBucketName')
    } catch (e) {
      message.error('上传失败请检查网络设置')
      return e
    }
  },
  /**
   * 读取线上文件
   * @param url 线上文件地址
   * @returns {Promise<unknown>}
   */
  readFile: (url) => {
    return new Promise((resolve, reject) => {
      axios({ url: url }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

