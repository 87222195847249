<template>
  <a-popover
    v-model="visible"
    trigger="click"
    placement="topLeft"
    overlay-class-name="font-size-dialog"
  >
    <div slot="content" class="wrapper" :style="fontStyle">
      <div class="option-header">
        <div class="title">设置字体大小</div>
        <img class="close-btn" :src="require('@/assets/common/close.png')" alt="" @click="visible = !visible">
      </div>
      <div class="main">
        <div v-for="(item, index) in list" :key="item.value" class="list" @click="fontSizeChange(item.value)">
          <div :class="['label', index === 0 ? 'fs-15' : index === list.length - 1 ? 'fs-22' : '']">{{ item.label }}
          </div>
          <div class="btn-wrapper">
            <div :class="['btn', fontSize === item.value ? 'active' : '']" />
          </div>
        </div>
        <div class="line" />
      </div>
    </div>
    <div class="font-size-btn">
      <img class="word-size" :src="require('@/assets/common/font_size.png')" alt="">
      <span class="text">字体</span>
    </div>
  </a-popover>
</template>

<script>
export default {
  name: 'FontSize',
  props: {
    fontStyle: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    fontSize: {
      type: [Number, String],
      default: 33
    }
  },
  data() {
    return {
      visible: false,
      list: [{
        label: 'A',
        value: 13
      }, {
        label: '标准',
        value: 16
      }, {
        label: '',
        value: 24
      }, {
        label: '',
        value: 28
      }, {
        label: '',
        value: 33
      }, {
        label: '',
        value: 38
      }, {
        label: '',
        value: 40
      }, {
        label: 'A',
        value: 42
      }]
    }
  },
  methods: {
    fontSizeChange(value) {
      this.$store.dispatch('app/setSize', value)
    }
  }
}
</script>

<style lang="scss">
.font-size-dialog {
  .ant-popover-inner {
    border-radius: 16px;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
}
</style>

<style scoped lang="scss">

.wrapper {
  background-color: #fff;
  width: 375px;
  border-radius: 16px;
  font-size: 16px;
  color: #333;
  padding-bottom: 20px;

  .option-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0 20px 0 28px;
    border-bottom: 1px solid #EEEEEE;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
    }

    .close-btn {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .main {
    display: flex;
    align-items: flex-end;
    position: relative;
    padding-top: 20px;

    .list {
      z-index: 2;

      &:last-child {
        margin-right: 0;
      }

      .label {
        text-align: center;
        margin-bottom: 10px;
      }

      .fs-15 {
        font-size: 15px;
      }

      .fs-22 {
        font-size: 22px;
      }

      .btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 47px;
        height: 40px;

        .btn {
          background-color: #E5E5E5;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          cursor: pointer;
        }

        .active {
          background-color: #1976FF;
          width: 20px;
          height: 20px;
        }
      }
    }

    .line {
      background-color: #E5E5E5;
      height: 2px;
      margin: 0 23px;
      position: absolute;
      bottom: 19px;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
}

.font-size-btn {
  width: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .word-size {
    width: 16px;
    height: 16px;
  }
  .text{
    font-size: 15px;
    line-height: 21px;
    color: #333333;
  }
}

</style>
