import { setStorage, getStorage, removeStorage, removeAllStorage } from '@/utils/storage'
import * as mutationTypes from '@/store/mutation-types'

const token = mutationTypes.SMART_TEACHING_SYSTEM_TOKEN
const userId = mutationTypes.SMART_TEACHING_SYSTEM_USER_ID
const phone = mutationTypes.SMART_TEACHING_SYSTEM_PHONE
const userName = mutationTypes.SMART_TEACHING_SYSTEM_USER_NAME
const userInfo = mutationTypes.SMART_TEACHING_SYSTEM_USER_INFO
const classData = mutationTypes.SMART_TEACHING_SYSTEM_CLASS_DATA
const evaluateUserId = mutationTypes.SMART_TEACHING_SYSTEM_EVALUATE_USER_ID
const accessKeyId = mutationTypes.SMART_TEACHING_SYSTEM_ACCESS_KEY_ID
const bucketName = mutationTypes.SMART_TEACHING_SYSTEM_BUCKET_NAME
const accessKeySecret = mutationTypes.SMART_TEACHING_SYSTEM_ACCESS_KEY_SECRET
const ossParam = mutationTypes.SMART_TEACHING_SYSTEM_OSS_PARAM
const waitingList = mutationTypes.SMART_TEACHING_SYSTEM_WAITING_LIST
const activeAnswer = mutationTypes.SMART_TEACHING_SYSTEM_ACTIVE_ANSWER
const classroom = mutationTypes.SMART_TEACHING_SYSTEM_CLASSROOM
const pkSelectBook = mutationTypes.SMART_TEACHING_SYSTEM_PK_SELECT_BOOK
const maximized = mutationTypes.SMART_TEACHING_SYSTEM_MAXIMIZED
const minimized = mutationTypes.SMART_TEACHING_SYSTEM_MINIMIZED
const windowSize = mutationTypes.SMART_TEACHING_SYSTEM_WINDOW_SIZE
const wordList = mutationTypes.SMART_TEACHING_SYSTEM_WORD_LIST
// token
export function setToken(val) {
  return setStorage(token, val)
}

export function getToken() {
  return getStorage(token)
}

export function removeToken() {
  return removeStorage(token)
}

// userId
export function setUserId(val) {
  return setStorage(userId, val)
}

export function getUserId() {
  return getStorage(userId)
}

export function removeUserId() {
  return removeStorage(userId)
}

// phone
export function setPhone(val) {
  return setStorage(phone, val)
}

export function getPhone() {
  return getStorage(phone)
}

export function removePhone() {
  return removeStorage(phone)
}

// userName
export function setUserName(val) {
  return setStorage(userName, val)
}

export function getUserName() {
  return getStorage(userName)
}

export function removeUserName() {
  return removeStorage(userName)
}

// wordList
export function setWordList(val) {
  return setStorage(wordList, val)
}

export function getWordList() {
  return getStorage(wordList)
}

export function removeWordList() {
  return removeStorage(wordList)
}
// maximized
export function setMaximized(val) {
  return setStorage(maximized, val)
}

export function getMaximized() {
  return getStorage(maximized)
}

export function removeMaximized() {
  return removeStorage(maximized)
}

// minimized
export function setMinimized(val) {
  return setStorage(minimized, val)
}

export function getMinimized() {
  return getStorage(minimized)
}

export function removeMinimized() {
  return removeStorage(minimized)
}
// windowSize
export function setWindowSize(val) {
  return setStorage(windowSize, val)
}

export function getWindowSize() {
  return getStorage(windowSize)
}

export function removeWindowSize() {
  return removeStorage(windowSize)
}

// userInfo
export function setUserInfo(val) {
  return setStorage(userInfo, val)
}

export function getUserInfo() {
  return getStorage(userInfo)
}

export function removeUserInfo() {
  return removeStorage(userInfo)
}

// classData
export function setClassData(val) {
  return setStorage(classData, val)
}

export function getClassData() {
  return getStorage(classData)
}

export function removeClassData() {
  return removeStorage(classData)
}

// waitingList
export function setWaitingList(val) {
  return localStorage.setItem(waitingList, JSON.stringify(val))
}

export function getWaitingList() {
  return JSON.parse(localStorage.getItem(waitingList)) || []
}

export function removeWaitingList() {
  return localStorage.removeItem(waitingList)
}

// activeAnswer
export function setActiveAnswer(val) {
  return localStorage.setItem(activeAnswer, JSON.stringify(val))
}

export function getActiveAnswer() {
  return JSON.parse(localStorage.getItem(activeAnswer)) || null
}

export function removeActiveAnswer() {
  return localStorage.removeItem(activeAnswer)
}

// classroom
export function setClassRoom(val) {
  return localStorage.setItem(classroom, JSON.stringify(val))
}

export function getClassRoom() {
  return JSON.parse(localStorage.getItem(classroom)) || null
}

export function removeClassRoom() {
  return localStorage.removeItem(classroom)
}

// pkSelectBook
export function setPkSelectBook(val) {
  return localStorage.setItem(pkSelectBook, JSON.stringify(val))
}

export function getPkSelectBook() {
  return JSON.parse(localStorage.getItem(pkSelectBook)) || null
}

export function removePkSelectBook() {
  return localStorage.removeItem(pkSelectBook)
}

// evaluateUserId
export function setEvaluateUserId(val) {
  return setStorage(evaluateUserId, val)
}

export function getEvaluateUserId() {
  return getStorage(evaluateUserId)
}

export function removeEvaluateUserId() {
  return removeStorage(evaluateUserId)
}
// accessKeyId
export function setAccessKeyId(val) {
  return setStorage(accessKeyId, val)
}

export function getAccessKeyId() {
  return getStorage(accessKeyId)
}

export function removeAccessKeyId() {
  return removeStorage(accessKeyId)
}
// bucketName
export function setBucketName(val) {
  return setStorage(bucketName, val)
}

export function getBucketName() {
  return getStorage(bucketName)
}

export function removeBucketName() {
  return removeStorage(bucketName)
}
// accessKeySecret
export function setAccessKeySecret(val) {
  return setStorage(accessKeySecret, val)
}

export function getAccessKeySecret() {
  return getStorage(accessKeySecret)
}

export function removeAccessKeySecret() {
  return removeStorage(accessKeySecret)
}
// ossParam
export function setOssParam(val) {
  return setStorage(ossParam, val)
}

export function getOssParam() {
  return getStorage(ossParam)
}

export function removeOssParam() {
  return removeStorage(ossParam)
}

// reset all
export function removeAll() {
  removeWaitingList()
  removeActiveAnswer()
  removeClassRoom()
  removePkSelectBook()
  return removeAllStorage([token, userId, userName, phone, wordList, classData, userInfo, evaluateUserId, ossParam, accessKeyId, bucketName, bucketName, accessKeySecret])
}
