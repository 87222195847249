import _debounce from 'lodash/debounce'

let fn = null
export default {
  inserted(el, binding) {
    fn = _debounce(binding.value, 2000, {
      leading: true,
      trailing: false
    })
    el.addEventListener('click', fn)
  },
  unbind(el) {
    fn && el.removeEventListener('click', fn)
  }
}
