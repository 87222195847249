export default {
  inserted(el, binding) {
    el.addEventListener('scroll', function() {
      const sign = 0
      const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
      if (scrollDistance <= sign) {
        binding.value()
      }
    })
  }
}

