const state = {
  wordBook: localStorage.getItem('wordBook') ? JSON.parse(localStorage.getItem('wordBook')) : '',
  wordBookMaterial: localStorage.getItem('wordBookMaterial') ? JSON.parse(localStorage.getItem('wordBookMaterial')) : '',
  wordBookUnit: localStorage.getItem('wordBookUnit') ? JSON.parse(localStorage.getItem('wordBookUnit')) : ''
}

const mutations = {
  // reset all
  SMART_TEACHING_SYSTEM_RESET_ALL(state, data) {
    const remove = ['wordBook', 'wordBookMaterial', 'wordBookUnit']
    for (const key in state) {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        if (remove.indexOf(key) > -1) {
          state[key] = data
          localStorage.removeItem(key)
        }
      }
    }
  },
  // wordBook
  SMART_TEACHING_SYSTEM_WORD_BOOK(state, data) {
    state.wordBook = data
    data === '' ? localStorage.removeItem('wordBook') : localStorage.setItem('wordBook', JSON.stringify(data))
  },
  // wordBookMaterial
  SMART_TEACHING_SYSTEM_WORD_BOOK_MATERIAL(state, data) {
    state.wordBookMaterial = data
    data === '' ? localStorage.removeItem('wordBookMaterial') : localStorage.setItem('wordBookMaterial', JSON.stringify(data))
  },
  // wordBookUnit
  SMART_TEACHING_SYSTEM_WORD_BOOK_UNIT(state, data) {
    state.wordBookUnit = data
    data === '' ? localStorage.removeItem('wordBookUnit') : localStorage.setItem('wordBookUnit', JSON.stringify(data))
  }
}

const actions = {
  // 清除所有信息
  resetAll({ commit }) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_RESET_ALL', '')
      resolve()
    })
  },
  // 设置选中单词书籍
  setWordBook({ commit }, data) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_WORD_BOOK', data)
      resolve(data)
    })
  },
  // 设置选中单词书籍教材版本
  setWordBookMaterial({ commit }, data) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_WORD_BOOK_MATERIAL', data)
      resolve(data)
    })
  },
  // 设置选中单词书籍单元
  setWordBookUnit({ commit }, data) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_WORD_BOOK_UNIT', data)
      resolve(data)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
