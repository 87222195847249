import * as storage from '@/utils/auth'
import * as types from '../mutation-types'

const state = {
  wordList: storage.getWordList()
}

const mutations = {
  // wordList
  [types.SMART_TEACHING_SYSTEM_WORD_LIST](state, data) {
    state.wordList = data
    data === '' ? storage.removeWordList() : storage.setWordList(data)
  }
}

const actions = {
  // 清除单词列表
  resetAll({ commit }) {
    commit(types.SMART_TEACHING_SYSTEM_WORD_LIST, '')
  },
  // 设置单词列表
  setWordList({ commit }, data) {
    return new Promise(resolve => {
      commit(types.SMART_TEACHING_SYSTEM_WORD_LIST, data)
      resolve(data)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
