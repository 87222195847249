const getters = {
  inElectron: state => state.app.inElectron,
  size: state => state.app.size,
  measureRateScale: state => state.app.measureRateScale,
  bookMaterial: state => state.app.bookMaterial,
  token: state => state.user.token,
  userId: state => state.user.userId,
  userName: state => state.user.userName,
  phone: state => state.user.phone,
  userInfo: state => state.user.userInfo,
  classData: state => state.user.classData,
  accessKeyId: state => state.user.accessKeyId,
  bucketName: state => state.user.bucketName,
  accessKeySecret: state => state.user.accessKeySecret,
  ossParam: state => state.user.ossParam,
  waitingList: state => state.user.waitingList,
  activeAnswer: state => state.user.activeAnswer,
  classroom: state => state.user.classroom,
  pkSelectBook: state => state.user.pkSelectBook,
  wordTitleName: state => state.word.wordTitleName,
  wordBook: state => state.word.wordBook,
  wordBookMaterial: state => state.word.wordBookMaterial,
  wordBookUnit: state => state.word.wordBookUnit,
  wordList: state => state.pk.wordList,
  maximized: state => state.window.maximized,
  textBook: state => state.text.textBook,
  textBookMaterial: state => state.text.textBookMaterial,
  textBookUnit: state => state.text.textBookUnit,
  audioList: state => state.params.audioList,
  paperGroupList: state => state.params.paperGroupList,
  auxiliaryBook: state => state.auxiliary.auxiliaryBook,
  auxiliaryBookUnit: state => state.auxiliary.auxiliaryBookUnit,
  auxiliaryPaper: state => state.auxiliary.auxiliaryPaper,
  specialBook: state => state.special.specialBook,
  specialBookUnit: state => state.special.specialBookUnit,
  specialPaper: state => state.special.specialPaper
}
export default getters
