const state = {
  audioList: [],
  paperGroupList: [] // 题组列表
}

const mutations = {
  // audioList
  SET_AUDIO_LIST: (state, list) => {
    state.audioList = list
  },
  SET_PAPER_GROUP_LIST: (state, list) => {
    state.paperGroupList = list
  }
}

const actions = {
  // 设置音频数据
  setAudioList({ commit }, list) {
    commit('SET_AUDIO_LIST', list)
  },
  // 清除音频数据
  clearAudioList({ commit }) {
    commit('SET_AUDIO_LIST', [])
  },
  // 设置试题列表
  setPaperGroupList({ commit }, list) {
    commit('SET_PAPER_GROUP_LIST', list)
  },
  // 清除试题列表
  clearPaperGroupList({ commit }) {
    commit('SET_PAPER_GROUP_LIST', [])
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
