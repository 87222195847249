const state = {
  auxiliaryBook: localStorage.getItem('auxiliaryBook') ? JSON.parse(localStorage.getItem('auxiliaryBook')) : '',
  auxiliaryBookUnit: localStorage.getItem('auxiliaryBookUnit') ? JSON.parse(localStorage.getItem('auxiliaryBookUnit')) : '',
  auxiliaryPaper: localStorage.getItem('auxiliaryPaper') ? JSON.parse(localStorage.getItem('auxiliaryPaper')) : ''
}

const mutations = {
  // reset all
  SMART_TEACHING_SYSTEM_RESET_ALL(state, data) {
    const remove = ['auxiliaryBook', 'auxiliaryBookUnit', 'auxiliaryPaper']
    for (const key in state) {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        if (remove.indexOf(key) > -1) {
          state[key] = data
          localStorage.removeItem(key)
        }
      }
    }
  },
  // auxiliaryBook
  SMART_TEACHING_SYSTEM_AUXILIARY_BOOK(state, data) {
    state.auxiliaryBook = data
    data === '' ? localStorage.removeItem('auxiliaryBook') : localStorage.setItem('auxiliaryBook', JSON.stringify(data))
  },
  // auxiliaryBookUnit
  SMART_TEACHING_SYSTEM_AUXILIARY_BOOK_UNIT(state, data) {
    state.auxiliaryBookUnit = data
    data === '' ? localStorage.removeItem('auxiliaryBookUnit') : localStorage.setItem('auxiliaryBookUnit', JSON.stringify(data))
  },
  // auxiliaryPaper
  SMART_TEACHING_SYSTEM_AUXILIARY_PAPER(state, data) {
    state.auxiliaryPaper = data
    data === '' ? localStorage.removeItem('auxiliaryPaper') : localStorage.setItem('auxiliaryPaper', JSON.stringify(data))
  }
}

const actions = {
  // 清除所有信息
  resetAll({ commit }) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_RESET_ALL', '')
      resolve()
    })
  },
  // 设置选中教辅书籍
  setAuxiliaryBook({ commit }, data) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_AUXILIARY_BOOK', data)
      resolve(data)
    })
  },
  // 设置选中教辅书籍单元
  setAuxiliaryBookUnit({ commit }, data) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_AUXILIARY_BOOK_UNIT', data)
      resolve(data)
    })
  },
  // 设置选中教辅书籍单元试题
  setAuxiliaryPaper({ commit }, data) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_AUXILIARY_PAPER', data)
      resolve(data)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
