import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './permission'
import store from './store'
import common from './utils/evaluats'
import service from './utils/service'
import '@/utils/filter'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import '@/styles/index.scss'
// ant-design-vue
Vue.use(Antd)

const dayjs = require('dayjs')
Vue.prototype.$dayjs = dayjs

import bus from '@/bus'
Vue.prototype.$bus = bus

// lodash工具类
import _ from 'lodash'
Vue.prototype.$_ = _
Vue.prototype.common = common
Vue.prototype.$service = service

Vue.config.productionTip = false

// 自定义指令
import directive from '@/directive/index'
Vue.use(directive)

// 节流技术
let flag = false
Vue.prototype.$throttle = function(callBack, wait = 300) {
  if (!flag) {
    flag = true
    setTimeout(() => {
      callBack()
      flag = false
    }, wait)
  }
}
// 防抖
let debounceObj = null
Vue.prototype.$debounce = function(time, callBack) {
  if (debounceObj) {
    clearTimeout(debounceObj)
  }
  debounceObj = setTimeout(() => {
    callBack()
    debounceObj = null
  }, time)
}

import MyTitle from '@/components/common/MyTitle'
import Footer from '@/components/common/Footer'
import ActivationCode from '@/components/vip/ActivationCode'
import Sidebar from '@/components/common/Sidebar'
Vue.component('MyTitle', MyTitle)
Vue.component('Footer', Footer)
Vue.component('ActivationCode', ActivationCode)
Vue.component('Sidebar', Sidebar)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
