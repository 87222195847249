import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// quit -- 用于点击关闭时判断是否能关闭程序
const routes = [
  {
    path: '/',
    redirect: 'home'
  },
  {
    path: '/home',
    name: 'Home',
    meta: { quit: true, isHome: true, title: '首页' },
    component: () => import('@/views/Home')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { quit: true, isHome: true, title: '登录页' },
    component: () => import('@/views/Login')
  },
  {
    path: '/smart-word',
    name: 'SmartWord',
    meta: { quit: true, title: '智能单词' },
    component: () => import('@/views/SmartWord/index'),
    children: [
      {
        path: 'book-list',
        name: 'WordBookList',
        meta: { quit: true, title: '书籍列表' },
        component: () => import('@/views/SmartWord/bookList')
      },
      {
        path: 'bookshelf',
        name: 'WordBookshelf',
        meta: { quit: true, title: '书架' },
        component: () => import('@/views/SmartWord/bookshelf.vue')
      },
      {
        path: 'book-detail',
        name: 'WordBookDetail',
        meta: { quit: false, title: '书籍详情' },
        component: () => import('@/views/SmartWord/bookDetail')
      },
      {
        path: 'word-read',
        name: 'WordRead',
        meta: { quit: false, answer: true, title: '单词作答' },
        component: () => import('@/views/SmartWord/wordRead')
      }
    ]
  },
  {
    path: '/listen-read-text',
    name: 'ListenReadText',
    meta: { quit: true, title: '课文听读' },
    component: () => import('@/views/ListenReadText/index'),
    children: [
      {
        path: 'book-list',
        name: 'TextList',
        meta: { quit: true, title: '书籍列表' },
        component: () => import('@/views/ListenReadText/bookList')
      },
      {
        path: 'bookshelf',
        name: 'TextBookshelf',
        meta: { quit: true, title: '书架' },
        component: () => import('@/views/ListenReadText/bookshelf.vue')
      },
      {
        path: 'book-detail',
        name: 'TextBookDetail',
        meta: { quit: true, answer: true, title: '书籍详情' },
        component: () => import('@/views/ListenReadText/bookDetail')
      }
    ]
  },
  {
    path: '/teaching-auxiliary',
    name: 'TeachingAuxiliary',
    meta: { quit: true, title: '我的教辅' },
    component: () => import('@/views/TeachingAuxiliary/index'),
    children: [
      {
        path: 'book-list',
        name: 'AuxiliaryBookList',
        meta: { quit: true, title: '书籍列表' },
        component: () => import('@/views/TeachingAuxiliary/bookList')
      },
      {
        path: 'bookshelf',
        name: 'AuxiliaryBookshelf',
        meta: { quit: true, title: '书架' },
        component: () => import('@/views/TeachingAuxiliary/bookshelf.vue')
      },
      {
        path: 'auxiliary-answer',
        name: 'AuxiliaryAnswer',
        meta: { quit: true },
        component: () => import('@/views/TeachingAuxiliary/answer')
      }
    ]
  },
  {
    path: '/regional-special',
    name: 'RegionalSpecial',
    meta: { quit: true, title: '本地专版' },
    component: () => import('@/views/RegionalSpecial/index'),
    children: [
      {
        path: 'book-list',
        name: 'SpecialBookList',
        meta: { quit: true, title: '书籍列表' },
        component: () => import('@/views/RegionalSpecial/bookList')
      },
      {
        path: 'bookshelf',
        name: 'SpecialBookshelf',
        meta: { quit: true, title: '书架' },
        component: () => import('@/views/RegionalSpecial/bookshelf.vue')
      }
    ]
  },
  {
    path: '/pk-container',
    name: 'PkContainer',
    meta: { quit: true },
    component: () => import('@/views/Pk'),
    children: [
      {
        path: '/create-pk',
        name: 'CreatePk',
        meta: { quit: true },
        component: () => import('@/views/Pk/createPk')
      },
      {
        path: '/pk-select-book',
        name: 'PkSelectBook',
        meta: { quit: true },
        component: () => import('@/views/Pk/selectBook')
      },
      {
        path: '/pk-select-unit',
        name: 'PkSelectUnit',
        meta: { quit: true },
        component: () => import('@/views/Pk/selectUnit')
      },
      {
        path: '/pk-select-word',
        name: 'PkSelectWord',
        meta: { quit: true },
        component: () => import('@/views/Pk/selectWord')
      },
      {
        path: '/pk',
        name: 'Pk',
        meta: { quit: true, isPk: true },
        component: () => import('@/views/Pk/pk')
      },
      {
        path: '/pk-record',
        name: 'PkRecord',
        meta: { quit: true },
        component: () => import('@/views/Pk/pkRecord')
      }
    ]
  },
  {
    path: '/Text',
    name: 'TextPage',
    meta: { quit: true },
    component: () => import('@/views/Test/index'),
    children: [
      {
        // 手动模式
        path: 'accent-detail',
        name: 'AccentDetail',
        meta: { quit: true, answer: true, title: '听说题' },
        component: () => import('@/views/Test/accentDetail')
      },
      // 自动模式
      {
        path: 'paper-detail',
        name: 'PaperDetail',
        meta: { quit: true, answer: true, title: '听说题' },
        component: () => import('@/views/Test/paperDetail')
      },
      // 笔试题
      {
        path: 'written-detail',
        name: 'WrittenDetail',
        meta: { quit: true, answer: true, title: '笔试题' },
        component: () => import('@/views/Test/writtenDetail')
      }
    ]
  }
]

// 防止路由重复点击报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject) }
  return originalPush.call(this, location).catch((err) => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) { return originalReplace.call(this, location, onResolve, onReject) }
  return originalReplace.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
