import { setStorage, getStorage } from '@/utils/storage'

const state = {
  inElectron: true,
  size: getStorage('smartTeachingFontSize') || 33,
  // measureRateScale: {} // 评测松紧度
  measureRateScale: { 1: '1.00', 2: '1.00', 3: '1.00', 12: '1.00', 15: '1.15' }, // 评测松紧度
  bookMaterial: {} // 教材版本
}

const mutations = {
  SET_SIZE: (state, size) => {
    state.size = size
    setStorage('smartTeachingFontSize', size)
  },
  SET_IN_ELECTRON: (state, inElectron) => {
    state.inElectron = inElectron
  },
  SET_MEASURE_RATE_SCALE: (state, measureRateScale) => {
    state.measureRateScale = measureRateScale
  },
  SET_BOOK_MATERIAL: (state, bookMaterial) => {
    state.bookMaterial = bookMaterial
  }
}

const actions = {
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
  // 设置当前是否处于 Electron 内
  setInElectron({ commit }, inElectron) {
    commit('SET_IN_ELECTRON', inElectron)
  },
  /**
   * 设置评测的松紧度
   * @param commit
   * @param measureRateScale
   */
  setMeasureRateScale({ commit }, measureRateScale) {
    commit('SET_MEASURE_RATE_SCALE', measureRateScale)
  },
  // 设置教材版本
  setBookMaterial({ commit }, bookMaterial) {
    commit('SET_BOOK_MATERIAL', bookMaterial)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
