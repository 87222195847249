const state = {
  specialBook: localStorage.getItem('specialBook') ? JSON.parse(localStorage.getItem('specialBook')) : '',
  specialBookUnit: localStorage.getItem('specialBookUnit') ? JSON.parse(localStorage.getItem('specialBookUnit')) : '',
  specialPaper: localStorage.getItem('specialPaper') ? JSON.parse(localStorage.getItem('specialPaper')) : ''
}

const mutations = {
  // reset all
  SMART_TEACHING_SYSTEM_RESET_ALL(state, data) {
    const remove = ['specialBook', 'specialBookUnit', 'specialPaper']
    for (const key in state) {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        if (remove.indexOf(key) > -1) {
          state[key] = data
          localStorage.removeItem(key)
        }
      }
    }
  },
  // specialBook
  SMART_TEACHING_SYSTEM_SPECIAL_BOOK(state, data) {
    state.specialBook = data
    data === '' ? localStorage.removeItem('specialBook') : localStorage.setItem('specialBook', JSON.stringify(data))
  },
  // specialBookUnit
  SMART_TEACHING_SYSTEM_SPECIAL_BOOK_UNIT(state, data) {
    state.specialBookUnit = data
    data === '' ? localStorage.removeItem('specialBookUnit') : localStorage.setItem('specialBookUnit', JSON.stringify(data))
  },
  // specialPaper
  SMART_TEACHING_SYSTEM_SPECIAL_PAPER(state, data) {
    state.specialPaper = data
    data === '' ? localStorage.removeItem('specialPaper') : localStorage.setItem('specialPaper', JSON.stringify(data))
  }
}

const actions = {
  // 清除所有信息
  resetAll({ commit }) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_RESET_ALL', '')
      resolve()
    })
  },
  // 设置选中专版书籍
  setSpecialBook({ commit }, data) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_SPECIAL_BOOK', data)
      resolve(data)
    })
  },
  // 设置选中专版书籍单元
  setSpecialBookUnit({ commit }, data) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_SPECIAL_BOOK_UNIT', data)
      resolve(data)
    })
  },
  // 设置选中专版书籍单元试题
  setSpecialPaper({ commit }, data) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_SPECIAL_PAPER', data)
      resolve(data)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
