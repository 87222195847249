import axios from 'axios'
import store from '@/store'
import { message, Modal } from 'ant-design-vue'
import { getToken } from '@/utils/auth'
import md5 from 'js-md5'
import { generateUUID, getUUID, setUUID } from './index'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 60 // request timeout
})

const whiteList = ['/login']

// request interceptor -- 请求拦截器
service.interceptors.request.use(
  config => {
    // AI听说课堂处理
    if (store.getters.token || whiteList.indexOf(config.url) === -1) {
      const signStr = JSON.stringify(config.method === 'get' ? config.params : config.data)
      const release = '1.0.0'
      const device = 'web'
      const apps = 203
      let udid = getUUID()
      if (!udid) {
        udid = generateUUID(32)
        setUUID(udid)
      }
      const sign = md5(signStr + '_Sts100#@')
      config.headers = {
        ...config.headers,
        udid,
        sign,
        release,
        device,
        apps,
        authToken: getToken()
      }
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor -- 响应拦截器
service.interceptors.response.use(
  response => {
    const { data } = response
    // eslint-disable-next-line prefer-const
    data.state = Number(data.state)
    const { note, state } = data
    if (state !== 11) {
      if (state === 55) {
        message.error('服务器错误', 1.5)
      } else {
        const errMsg = note || 'Error'
        message.error(errMsg, 1.5)
      }
      if (state === 22) {
        store.dispatch('user/resetAll').then(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
          Modal.destroy()
        })
      }
      return Promise.reject(new Error(note || 'Error'))
    } else if (data instanceof Blob) {
      return response
    } else {
      return data
    }
  },
  error => {
    console.log('err' + error) // for debug
    const errMsg = error.message.includes('timeout') ? '网络请求超时，请重试' : error.message
    message.error(errMsg, 1.5)
    return Promise.reject(error)
  }
)

export default service
