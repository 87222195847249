export const SMART_TEACHING_SYSTEM_RESET_ALL = 'SMART_TEACHING_SYSTEM'
export const SMART_TEACHING_SYSTEM_TOKEN = 'SMART_TEACHING_SYSTEM_TOKEN'
export const SMART_TEACHING_SYSTEM_USER_ID = 'SMART_TEACHING_SYSTEM_USER_ID'
export const SMART_TEACHING_SYSTEM_USER_NAME = 'SMART_TEACHING_SYSTEM_USER_NAME'
export const SMART_TEACHING_SYSTEM_PHONE = 'SMART_TEACHING_SYSTEM_PHONE'
export const SMART_TEACHING_SYSTEM_USER_INFO = 'SMART_TEACHING_SYSTEM_USER_INFO'
export const SMART_TEACHING_SYSTEM_CLASS_DATA = 'SMART_TEACHING_SYSTEM_CLASS_DATA'
export const SMART_TEACHING_SYSTEM_EVALUATE_USER_ID = 'SMART_TEACHING_SYSTEM_EVALUATE_USER_ID'
export const SMART_TEACHING_SYSTEM_ACCESS_KEY_ID = 'SMART_TEACHING_SYSTEM_ACCESS_KEY_ID'
export const SMART_TEACHING_SYSTEM_ACCESS_KEY_SECRET = 'SMART_TEACHING_SYSTEM_ACCESS_KEY_SECRET'
export const SMART_TEACHING_SYSTEM_BUCKET_NAME = 'SMART_TEACHING_SYSTEM_BUCKET_NAME'
export const SMART_TEACHING_SYSTEM_OSS_PARAM = 'SMART_TEACHING_SYSTEM_OSS_PARAM'
export const SMART_TEACHING_SYSTEM_WAITING_LIST = 'SMART_TEACHING_SYSTEM_WAITING_LIST'
export const SMART_TEACHING_SYSTEM_ACTIVE_ANSWER = 'SMART_TEACHING_SYSTEM_ACTIVE_ANSWER'
export const SMART_TEACHING_SYSTEM_CLASSROOM = 'SMART_TEACHING_SYSTEM_CLASSROOM'
export const SMART_TEACHING_SYSTEM_MAXIMIZED = 'SMART_TEACHING_SYSTEM_MAXIMIZED'
export const SMART_TEACHING_SYSTEM_MINIMIZED = 'SMART_TEACHING_SYSTEM_MINIMIZED'
export const SMART_TEACHING_SYSTEM_WINDOW_SIZE = 'SMART_TEACHING_SYSTEM_WINDOW_SIZE'
export const SMART_TEACHING_SYSTEM_WORD_LIST = 'SMART_TEACHING_SYSTEM_WORD_LIST'
export const SMART_TEACHING_SYSTEM_PK_SELECT_BOOK = 'SMART_TEACHING_SYSTEM_PK_SELECT_BOOK'
