import request from '@/utils/request'

/**
 * 登录接口
 * @data {*} data
 */
export function login(data) {
  return request({
    url: '/apiv2/aiClassroom/user/teacherLogin',
    method: 'post',
    data
  })
}

/**
 * 退出登录
 * @data {*} data
 */
export function logout(data) {
  return request({
    url: '/apiv2/aiClassroom/user/logout',
    method: 'post',
    data
  })
}

/**
 * 教师通过原密码去修改密码
 * @data {*} data
 */
export function updatePwd(data) {
  return request({
    url: '/apiv2/aiClassroom/user/updatePwd',
    method: 'post',
    data
  })
}
