const UUID = require('uuid')
import { DefaultStudent } from '@/utils/mockData.js'
import md5 from 'js-md5'
import _ from 'lodash'
import store from '@/store'

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

// 设置 uuid
export function generatorUUID() {
  return getGeneratorUUID()
}

// 生成 uuid
function getGeneratorUUID() {
  return _.replace(UUID.v4(), /-/g, '') + md5(store.getters.userId + `${+new Date()}`).substring(0, 6)
}

const UUIDKey = 'backendV2-UUID-key'

export function getUUID() {
  return localStorage.getItem(UUIDKey)
}

export function setUUID(uuid) {
  localStorage.setItem(UUIDKey, uuid)
}

// 生成uuid
export const generateUUID = (len) => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  const uuid = []
  const radix = chars.length
  if (len) {
    for (let i = 0; i < len; i += 1) uuid[i] = chars[0 | Math.random() * radix]
  } else {
    // rfc4122, version 4 form
    let r

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
    uuid[14] = '4'

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (let i = 0; i < 36; i += 1) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16
        uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
      }
    }
  }
  return uuid.join('')
}

/**
 *  列表转树形 支持任意参数
 * @param flatList
 * @param idFieldName
 * @param parentKeyFieldName
 * @param fieldNameForChildren
 * @return {*[]}
 */
export function buildTree(flatList, idFieldName, parentKeyFieldName, fieldNameForChildren) {
  const rootElements = []
  const lookup = {}
  flatList.forEach(function(flatItem) {
    const itemId = flatItem[idFieldName]
    lookup[itemId] = flatItem
    flatItem[fieldNameForChildren] = []
  })
  flatList.forEach(function(flatItem) {
    const parentKey = flatItem[parentKeyFieldName]
    if (parentKey != null) {
      const parentObject = lookup[flatItem[parentKeyFieldName]]
      if (parentObject) {
        parentObject[fieldNameForChildren].push(flatItem)
      } else {
        rootElements.push(flatItem)
      }
    } else {
      rootElements.push(flatItem)
    }
  })
  return rootElements
}

export function map2list(map) {
  const list = []
  for (const key in map) {
    list.push({
      label: map[key],
      value: key
    })
  }
  return list
}

/**
 * 格式化时间
 * @Param Number duration 时长
 * @return String 00:00 格式
 **/
export const formatDuration = (duration) => {
  if (typeof duration === 'number') {
    return [parseInt(((duration / 60) % 60).toString()), parseInt((duration % 60).toString())].join(':').replace(/\b(\d)\b/g, '0$1')
  }
}
// 判断是否JSON格式
export const isJSON = (str) => {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str)
      return !!(typeof obj === 'object' && obj)
    } catch (e) {
      return false
    }
  } else {
    return false
  }
}
/**
 * 验证密码格式
 * @param val 密码文本
 * @returns {boolean} true: 格式正确；false: 格式错误
 */
export const validatePassword = (val) => {
  const reg = /^\S*(?=\S{8,25})(?=\S*\d)(?=\S*[a-z])(?=\S*[\W._])\S*$/
  return reg.test(val)
}
/**
 * 设置音标
 * @param {*} text 需处理的音标文本
 */
export const formatSoundmark = function(text) {
  // 处理中括号 '['、 ']'
  text = text.replace(/\[/g, '')
  text = text.replace(/]/g, '')
  text = text.split(';').join(',')
  return text
}
/**
 * 处理单词词性
 * @param pos 词性类型
 * @returns {string} 词性标识
 */
export const partsOfSpeech = (pos) => {
  let name
  switch (pos) {
    case '0':
      name = 'n.'
      break
    case '1':
      name = 'v.'
      break
    case '2':
      name = 'vt.'
      break
    case '3':
      name = 'vi.'
      break
    case '4':
      name = 'adj.'
      break
    case '5':
      name = 'adv.'
      break
    case '6':
      name = 'prep.'
      break
    case '7':
      name = 'pron.'
      break
    case '8':
      name = 'conj.'
      break
    case '9':
      name = 'art.'
      break
    case '10':
      name = 'num.'
      break
    case '11':
      name = 'pl.'
      break
    case '12':
      name = 'modal v.'
      break
    case '13':
      name = 'aux.v.'
      break
    case '14':
      name = 'interj.'
      break
    case '15':
      name = 'det.'
      break
    case '16':
      name = 'abbr.'
      break
    case '17':
      name = 'pt.'
      break
    case '18':
      name = 'pp.'
      break
    case '19':
      name = 'vt. & vi.'
      break
    case '20':
      name = 'comb.'
      break
    default:
      name = ''
  }
  return name
}

/**
 * 年级转换
 *
 */
export const gradeChange = (val) => {
  let newVal
  switch (Number(val)) {
    case 0:
    case 1:
    case 2:
    case 3:
    case 7:
      newVal = 7
      break
    case 4:
    case 5:
    case 6:
    case 8:
    default:
      newVal = 8
      break
  }
  return newVal
}

// 随机名字
export const randomName = () => {
  const name = []
  const xing = '赵钱孙李周吴郑王冯陈褚卫蒋沈韩杨朱秦尤许何吕施张孔曹严华金魏陶姜戚谢邹喻柏水窦章云苏潘葛奚范彭郎鲁韦昌马苗凤花方俞任袁柳酆鲍史唐费廉岑薛雷贺倪汤滕殷罗毕郝邬安常乐于时傅皮卞齐康伍余元卜顾孟平黄和穆萧尹姚邵湛汪祁毛禹狄米贝明臧计伏成戴谈宋茅庞熊纪舒屈项祝董梁杜阮蓝闵席季麻强贾路娄危江童颜郭梅盛林刁钟徐邱骆高夏蔡田樊胡凌霍虞万支柯昝管卢莫经房裘缪干解应宗丁宣贲邓郁单杭洪包诸左石崔吉钮龚程嵇邢滑裴陆荣翁荀羊於惠甄曲家封芮羿储靳汲邴糜松井段富巫乌焦巴弓牧隗山谷车侯宓蓬全郗班仰秋仲伊宫宁仇栾暴甘钭厉戎祖武符刘景詹束龙叶幸司韶郜黎蓟薄印宿白怀蒲邰从鄂索咸籍赖卓蔺屠蒙池乔阴鬱胥能苍双闻莘党翟谭贡劳逄'.split('')
  const ming = '志尚风睿振荣嘉慕宏远子瑜英逸翔飞才鸿博专弘方雨天成空开霁文言光明巍奕斌致建安阳舒珠长智刚胜意高达景昂奇思新强峰瀚钰星俊豪承福君昆琦彬炳阔广鹏运彭彰泰清厉辉昌邈河辰平晋宁轩腾知益永贞格华良力夫康骞礼越涵火正昊硕钊木聪宇元忠稷范耀宜茂彦锦程翰学晖州雄海资曦拔和蕴勇男城同玉涛向宾白锐典航乾晨德义韵苑霖名魄曜洽淼寿仙旭温纶剑毅然经武谨乐水峻石栋泽玥杰崇波勋磊柏伟山东烨掣恺修竹晔中锋兴浩物畅汉诚雅健年鲲盛立震皓跃易鹤珧大纵涆容珹实池双颜浦旷春信瑞赐敬晓羽理群用壮澹树骏桀楚量云龙喆生欢材延洁捷苍琪民烁峯朝来湃育凯旋日雪祺嗣业遥煜炫骥宝弼略悟玮甫迈哲坚秉艺'.split('')
  const xingFlag = Math.ceil(Math.random() * xing.length)
  const mingFlag = Math.ceil(Math.random() * ming.length)
  name[0] = xing[xingFlag]
  name[1] = ming[mingFlag]
  if (xingFlag > mingFlag) {
    name[2] = ming[xingFlag - mingFlag]
  }
  return name.join('')
}

/**
 * 生成 [m, n) 区间的随机整数
 * @param m Number 类型 最小值（大于等于）
 * @param n Number 类型 最大值（小于）
 * @returns {number}
 * m要小于n
 */
export const randomNum = (m, n) => {
  return Math.floor((n - m) * Math.random() + m)
}

export const objSort = (obj) => {
  const arr = Object.entries(obj)

  function compare(index) {
    return (a, b) => {
      a = a[index]
      b = b[index]
      if (a < b) return -1
      if (a > b) return 1
      return 0
    }
  }

  arr.sort(compare(0))
  let str = ''
  for (let i = 0, len = arr.length; i < len; i += 1) {
    str += `${arr[i][0]}=${arr[i][1]}`
    if (i !== len - 1) {
      str += '&'
    }
  }
  return str
}

/**
 * 去评测文本
 * fix issue：处理逻辑不对，已重写 by：rixin 2021/07/12
 */
export const evaluate = function(text) {
  const t = text || ''
  if (t === '') return
  // 先处理大括号，拿到大括号内的文本
  const regBig = /\{.*?}/g
  const b = t.replace(regBig, (word) => {
    // 先去掉大括号，再取斜杠前面的内容
    return word.replace(/\{|}/g, '').split('/')[0]
  })
  // 再处理中括号，拿到中括号内的文本
  const regMedium = /(\[.*?])/g
  const m = b.replace(regMedium, (word) => {
    return word.replace(/\[|]/g, '').split('/')[0]
  })
  // 最后去掉奇怪的分隔符，并给每行进行换行
  return m.replace(/\\/g, '/').replace(/’/g, '\'').replace(/[“”]/g, '"').replace(/[#\r]/g, '')
    .replace(/\n/g, '<br />')
}

export const randomList = list => {
  const data = _.cloneDeep(list)
  let i = data.length
  while (i) {
    const j = Math.floor(Math.random() * i--);
    [data[j], data[i]] = [data[i], data[j]]
  }
  return data
}

export function getCacheSize(type) {
  let obj = ''
  if (type === 'localStorage') {
    if (!window.localStorage) {
      console.log('浏览器不支持localStorage')
    } else {
      obj = window.localStorage
    }
  } else if (type === 'sessionStorage') {
    if (!window.sessionStorage) {
      console.log('浏览器不支持sessionStorage')
    } else {
      obj = window.sessionStorage
    }
  }
  if (obj !== '') {
    let size = 0
    for (const item in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(item)) {
        size += obj.getItem(item).length
      }
    }
    console.log('当前已用存储：' + (size / 1024).toFixed(2) + 'KB')
  }
}

export const setDefaultStudent = (list = []) => {
  if (!Array.isArray(list)) return []
  if (list.length < 2) {
    const classData = store.getters.classData
    const userInfo = store.getters.userInfo
    const students = DefaultStudent.map(el => {
      el = {
        ...el,
        gradeId: classData.gradeId, // 年级id
        clazzId: classData.id, // 班级id
        className: classData.name, // 班级名称
        actStatus: 0, // 用户状态 0=正常 1=禁用
        schoolId: userInfo.schoolId, // 学校id
        schoolName: userInfo.schoolName, // 学校名称
        provinceId: userInfo.provinceId, // 省id
        cityId: userInfo.cityId // 市id
      }
      return el
    })
    return [...list, ...students]
  } else {
    return [...list]
  }
}

