// 默认学生，当学生人数不足2个人时，使用下面虚拟学生填充
export const DefaultStudent = [
  {
    id: 'default1', // 学生ID
    realName: '学生1', // 学生真实名称
    name: '学生1', // 名称
    head: null, // 头像
    phone: '', // 手机号
    gradeId: '', // 年级id
    clazzId: '', // 班级id
    className: '', // 班级名称
    actStatus: 0, // 用户状态 0=正常 1=禁用
    schoolId: '', // 学校id
    schoolName: '', // 学校名称
    provinceId: '', // 省id
    cityId: '', // 市id
    districtId: '', // 区id
    loginAccount: '', // 登录账号，唯一，由后台自动生
    parentId: null, // 家长id
    kinshipTerms: null, // 学生针对某个家长的关系
    sequence: null, // 排序
    userType: null // 未知
  },
  {
    id: 'default2', // 学生ID
    realName: '学生2', // 学生真实名称
    name: '学生2', // 名称
    head: null, // 头像
    phone: '', // 手机号
    gradeId: '', // 年级id
    clazzId: '', // 班级id
    className: '', // 班级名称
    actStatus: 0, // 用户状态 0=正常 1=禁用， 2=注销中，3=已注销
    schoolId: '', // 学校id
    schoolName: '', // 学校名称
    provinceId: '', // 省id
    cityId: '', // 市id
    districtId: '', // 区id
    loginAccount: '', // 登录账号，唯一，由后台自动生
    parentId: null, // 家长id
    kinshipTerms: null, // 学生针对某个家长的关系
    sequence: null, // 排序
    userType: null // 未知
  }
]
