/**
 * 存储Storage
 */
export const setStorage = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  localStorage.setItem(name, content)
}

/**
 * 获取Storage
 */
export const getStorage = name => {
  if (!name) return
  let content = localStorage.getItem(name)
  try {
    content = JSON.parse(content)
    return content
  } catch (e) {
    return content
  }
}

/**
 * 删除Storage
 */
export const removeStorage = name => {
  if (!name) return
  localStorage.removeItem(name)
}

/**
 * 删除全部Storage
 */
export const removeAllStorage = name => {
  if (!(name instanceof Array)) return
  name.forEach(el => {
    localStorage.removeItem(el)
  })
}

