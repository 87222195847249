import * as storage from '@/utils/auth'
import * as types from '../mutation-types'

const state = {
  maximized: storage.getMaximized() || false, // 最大化状态
  minimized: storage.getMinimized() || false, // 最大化状态
  windowSize: storage.getWindowSize() || {} // 窗口大小
}

const mutations = {
  // reset all
  [types.SMART_TEACHING_SYSTEM_RESET_ALL](state, data) {
    const remove = ['maximized', 'minimized', 'size']
    for (const key in state) {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        if (remove.indexOf(key) > -1) {
          state[key] = data
        }
      }
    }
    storage.removeAll()
  },
  // maximized
  [types.SMART_TEACHING_SYSTEM_MAXIMIZED](state, data) {
    state.maximized = data
    data === '' ? storage.removeMaximized() : storage.setMaximized(data)
  },
  // minimized
  [types.SMART_TEACHING_SYSTEM_MINIMIZED](state, data) {
    state.minimized = data
    data === '' ? storage.removeMinimized() : storage.setMinimized(data)
  },
  // windowSize
  [types.SMART_TEACHING_SYSTEM_WINDOW_SIZE](state, data) {
    state.windowSize = data
    data === '' ? storage.removeWindowSize() : storage.setWindowSize(data)
  }
}

const actions = {
  // 清除所有信息
  resetAll({ commit }) {
    commit(types.SMART_TEACHING_SYSTEM_RESET_ALL, '')
  },
  // 设置窗口最大化状态
  setMaximized({ commit }, data) {
    commit(types.SMART_TEACHING_SYSTEM_MAXIMIZED, data)
  },
  // 设置窗口最小化状态
  setMinimized({ commit }, data) {
    commit(types.SMART_TEACHING_SYSTEM_MINIMIZED, data)
  },
  // 设置窗口大小信息
  setWindowSize({ commit }, data) {
    commit(types.SMART_TEACHING_SYSTEM_WINDOW_SIZE, data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
