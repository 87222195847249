const state = {
  textBook: localStorage.getItem('textBook') ? JSON.parse(localStorage.getItem('textBook')) : '',
  textBookMaterial: localStorage.getItem('textBookMaterial') ? JSON.parse(localStorage.getItem('textBookMaterial')) : '',
  textBookUnit: localStorage.getItem('textBookUnit') ? JSON.parse(localStorage.getItem('textBookUnit')) : ''
}

const mutations = {
  // reset all
  SMART_TEACHING_SYSTEM_RESET_ALL(state, data) {
    const remove = ['textBook', 'textBookUnit']
    for (const key in state) {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        if (remove.indexOf(key) > -1) {
          state[key] = data
          localStorage.removeItem(key)
        }
      }
    }
  },
  // textBook
  SMART_TEACHING_SYSTEM_TEXT_BOOK(state, data) {
    state.textBook = data
    data === '' ? localStorage.removeItem('textBook') : localStorage.setItem('textBook', JSON.stringify(data))
  },
  // textBookMaterial
  SMART_TEACHING_SYSTEM_TEXT_BOOK_MATERIAL(state, data) {
    state.textBookMaterial = data
    data === '' ? localStorage.removeItem('textBookMaterial') : localStorage.setItem('textBookMaterial', JSON.stringify(data))
  },
  // textBookUnit
  SMART_TEACHING_SYSTEM_TEXT_BOOK_UNIT(state, data) {
    state.textBookUnit = data
    data === '' ? localStorage.removeItem('textBookUnit') : localStorage.setItem('textBookUnit', JSON.stringify(data))
  }
}

const actions = {
  // 清除所有信息
  resetAll({ commit }) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_RESET_ALL', '')
      resolve()
    })
  },
  // 设置选中课文听读书籍
  setTextBook({ commit }, data) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_TEXT_BOOK', data)
      resolve(data)
    })
  },
  // 设置选中课文听读教材版本
  setTextBookMaterial({ commit }, data) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_TEXT_BOOK_MATERIAL', data)
      resolve(data)
    })
  },
  // 设置选中课文听读单元
  setTextBookUnit({ commit }, data) {
    return new Promise(resolve => {
      commit('SMART_TEACHING_SYSTEM_TEXT_BOOK_UNIT', data)
      resolve(data)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
