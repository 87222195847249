<template>
  <a-modal
    title="修改密码"
    :visible="visible"
    :width="450"
    :footer="null"
    :destroy-on-close="true"
    :mask-closable="false"
    :centered="true"
    @cancel="cancelChange"
  >
    <img slot="closeIcon" :src="require('@/assets/common/close.png')" alt="" class="close-icon">
    <a-form-model ref="ruleForm" layout="vertical" :model="ruleForm" :rules="rules">
      <a-form-model-item ref="oldPassword" label="请输入旧密码" prop="oldPassword">
        <a-input
          v-model="ruleForm.oldPassword"
          :type="showOldPassword ? undefined : 'password'"
          placeholder="请输入旧的密码"
          @blur="() => { $refs.oldPassword.onFieldBlur() }"
        >
          <template slot="suffix">
            <img
              v-if="showOldPassword"
              class="password-img"
              :src="require('@/assets/common/eye_active.png')"
              alt=""
              @click="showOldPassword=!showOldPassword"
            >
            <img
              v-else
              class="password-img"
              :src="require('@/assets/common/eye.png')"
              alt=""
              @click="showOldPassword=!showOldPassword"
            >
          </template>
        </a-input>
      </a-form-model-item>
      <a-form-model-item ref="newPassword" label="请输入新密码" prop="newPassword">
        <a-input
          v-model="ruleForm.newPassword"
          :type="showPassword ? undefined : 'password'"
          placeholder="请输入新的密码"
          @blur="() => { $refs.newPassword.onFieldBlur() }"
        >
          <template slot="suffix">
            <img
              v-if="showPassword"
              class="password-img"
              :src="require('@/assets/common/eye_active.png')"
              alt=""
              @click="showPassword=!showPassword"
            >
            <img
              v-else
              class="password-img"
              :src="require('@/assets/common/eye.png')"
              alt=""
              @click="showPassword=!showPassword"
            >
          </template>
        </a-input>
      </a-form-model-item>
      <a-form-model-item ref="confirmPassword" label="再次确认新密码" prop="confirmPassword">
        <a-input
          v-model="ruleForm.confirmPassword"
          :type="showConfirmPassword ? undefined : 'password'"
          placeholder="请输入登录密码"
          @blur="() => { $refs.confirmPassword.onFieldBlur() }"
        >
          <template slot="suffix">
            <img
              v-if="showConfirmPassword"
              class="password-img"
              :src="require('@/assets/common/eye_active.png')"
              alt=""
              @click="showConfirmPassword=!showConfirmPassword"
            >
            <img
              v-else
              class="password-img"
              :src="require('@/assets/common/eye.png')"
              alt=""
              @click="showConfirmPassword=!showConfirmPassword"
            >
          </template>
        </a-input>
      </a-form-model-item>
    </a-form-model>
    <div class="modal-footer">
      <a-button key="submit" class="modal-btn ok-btn mr-28" type="primary" :loading="confirmLoading" :disabled="confirmLoading" @click="confirmChange">确认修改</a-button>
      <a-button key="back" class="modal-btn cancel-btn" :disabled="confirmLoading" @click="cancelChange">取消</a-button>
    </div>
  </a-modal>
</template>

<script>
import { updatePwd } from '@/api/login.js'
import { validatePassword } from '@/utils/index.js'

export default {
  name: 'EditPassword',
  data() {
    const validateOldPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入旧密码'))
      } else {
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else if (!validatePassword(value)) { // 校验密码格式
        callback(new Error('密码必须包含字母、数字和特殊字符，并在8~25位之间！'))
      } else {
        if (this.ruleForm.confirmPassword !== '') {
          this.$refs.ruleForm.validateField('confirmPassword')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请重新输入密码'))
      } else if (!validatePassword(value)) { // 校验密码格式
        callback(new Error('密码必须包含字母、数字和特殊字符，并在8~25位之间！'))
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error('两次输入的密码不一致，请重新输入'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      confirmLoading: false,
      showOldPassword: false,
      showPassword: false,
      showConfirmPassword: false,
      ruleForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        oldPassword: [{ validator: validateOldPass, trigger: 'blur' }],
        newPassword: [{ validator: validatePass, trigger: 'blur' }],
        confirmPassword: [{ validator: validatePass2, trigger: 'blur' }]
      }
    }
  },
  computed: {
    // userId
    userId() {
      return this.$store.getters.userId
    }
  },
  methods: {
    changeVisible(bol) {
      this.visible = bol
      if (bol) {
        this.ruleForm = {
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        }
      }
    },
    confirmChange() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          const form = {
            oldPassword: this.ruleForm.oldPassword,
            newPassword: this.ruleForm.newPassword,
            confirmPassword: this.ruleForm.confirmPassword,
            teacherId: this.userId
          }
          updatePwd(form).then(res => {
            this.changeVisible(false)
            this.confirmLoading = false
            if (res['state'] !== 11) {
              console.error(res['message'])
            }
          }).catch(() => {
            this.confirmLoading = false
          })
        } else {
          return false
        }
      })
    },
    cancelChange() {
      this.$refs.ruleForm.resetFields()
      this.changeVisible(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-close{
  margin-left: 20px;
  width: 20px;
  height: 20px;
}
:deep(.ant-modal) {
  .ant-modal-content{
    border-radius: 32px;
  }
  .ant-modal-close{
    .ant-modal-close-x{
      .close-icon{
        width: 20px;
        height: 20px;
      }
    }
  }
  .ant-modal-header{
    padding: 14px 28px;
    border-radius: 32px 32px 0 0;
    .ant-modal-title{
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #333333;
    }
  }
  .ant-modal-body{
    padding: 28px 40px;
    .modal-input{
      width: 278px;
      height: 50px;
    }
    .ant-input-affix-wrapper{
      .ant-input{
        padding-right: 40px;
        padding-left: 20px;
        height: 50px;
        color: #333333;
        font-size: 16px;
        line-height: 22px;
        border-radius: 8px;
        &::placeholder{
          color: #999999;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    .modal-footer{
      display: flex;
      justify-content: center;
      align-items: center;
      .modal-btn{
        width: 160px;
        height: 40px;
        border-radius: 40px;
        border: none;
        font-size: 15px;
        line-height: 21px;
        &.ok-btn{
          color: #FFFFFF;
          background: linear-gradient(-90deg, #66ADFF 0%, #1976FF 100%);
          &:hover{
            background: linear-gradient(-90deg, #0565F3 0%, #0565F3 100%);
          }
        }
        &.cancel-btn{
          color: #666666;
          background: #F6F6F6;
          &:hover{
            background: #EFEFEF;
          }
        }
      }
    }
  }
}
.mr-28{
  margin-right: 28px;
}
:deep(.ant-form) {
  .ant-form-item {
    padding-bottom: 0;
    margin-bottom: 28px;

    &.ant-form-item-with-help {
      margin-bottom: 9px;
    }

    .ant-form-item-label {
      font-size: 17px;
      line-height: 24px;
      font-weight: bold;
      margin-bottom: 10px;
      padding: 0;

      .ant-form-item-required {
        &::before {
          display: none;
        }
      }
    }

    .ant-form-item-control-wrapper {
      .ant-input {
        height: 50px;
        border-radius: 0 25px 25px 25px;
        padding-left: 20px;
        padding-right: 20px;
        background: #F6F6F6;
      }

      .ant-input-suffix {
        right: 20px;
      }
    }
    .password-img{
      width: 20px;
      height: 20px;
    }
  }
}
</style>
