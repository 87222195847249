import Vue from 'vue'

// 设置 格式化时间展示格式
Vue.filter('timeFormat', function(time) {
  console.log('timeFormat', time)
  if (!time || isNaN(Number(time))) {
    return '00:00'
  } else {
    var minute = Math.floor(time / 60)
    if (minute < 10) { minute = '0' + minute }
    var second = Math.ceil(time % 60)
    if (second < 10) { second = '0' + second }
    return minute + ':' + second
  }
})
