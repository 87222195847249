import debounce from './debounce'
import loadMore from './loadMore'
const install = Vue => {
  Vue.directive('debounce', debounce)
  Vue.directive('loadMore', loadMore)
}
export default {
  install
}

