import common from './evaluats.js' // eslint-disable-line
import store from '../store'

export default {

  studentDisplayName(name, account) {
    return name || account
  },

  studentHeadValue(name, account, length) {
    return common.studentDisplayName(name, account).slice(length)
  },

  /**
   * 年级转换
   *
   */
  gradeChange: (val) => {
    let newVal
    switch (Number(val)) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 7:
        newVal = 7
        break
      case 4:
      case 5:
      case 6:
      case 8:
      default:
        newVal = 8
        break
    }
    return newVal
  },

  /**
   * 去#号
   * 中文双引号转英文引号
   *
   */
  formatText: (text) => {
    return text.replace(/’/g, '\'').replace(/[“”]/g, '"').replace(/[#\r]/g, '').replace(/\\/g, '/')
  },

  /**
   * 去.号
   *
   */
  formatText2: (text) => {
    return text.replace(/\./g, '')
  },

  tHandle: (text) => {
    if (!text) return ''
    const reg = /<t>.*?<\/t>/g
    return text.replace(reg, (text) => {
      return `<span style="display:inline-block;width: 45px;border-bottom: 1px solid #000;text-align: center;"></span>`
    })
  },

  /**
   * 截取3个答案
   *
   */
  cutOut: (text) => {
    const t = text.trim()
    const s = t.replace(/\n+/g, '\n')
    const arr = s.split('\n')
    const newArr = []
    for (let i = 0, len = arr.length; i < len; i += 1) {
      arr[i] = arr[i].trim()
      if (arr[i] !== '') newArr.push(arr[i])
      if (newArr.length === 3) break
    }
    return newArr.join('\n')
  },

  /**
   * 换行
   *
   */
  replaceBr: (text) => {
    return text.replace(/\n+/g, '<br />')
  },

  /**
   * 短文、句子单个单词分数
   * fis issue：修改注释两处逻辑 by：rixin 2020/07/12
   */
  wordMate: (content, details) => {
    if (!content) return null
    content = content.replace(/[’‘]/g, '\'')
    const reg = /{.*?}/g
    const htmlReg = /<\/?.+?\/?>/g
    const t = content.replace(htmlReg, '')
    const text = t.replace(reg, (word) => {
      const arr = word.replace(/\{|}/g, '').split('/') // 先把大括号去掉，碍事的家伙
      return arr[0]
    })
    const reg2 = /\[.*?]/g
    let newText = text.replace(reg2, (word) => {
      const arr = word.split('/')
      if (arr.length === 1 || arr[1] === ']') { // 对于中括号里包含大括号的情况，需判断分割后的数组长度
        return arr[0]
      }
      return arr[1]
    }).replace(/[{[\]}]/g, '')
    let signIndex = content.length - 1
    for (let p = details.length - 1; p >= 0; p -= 1) {
      if (details[p].snt_details) {
        for (let l = details[p].snt_details.length - 1; l >= 0; l -= 1) {
          const sntDetails = details[p].snt_details[l]
          const char = common.scoreLevel(sntDetails.score, sntDetails.char)
          const startIndex = newText.lastIndexOf(sntDetails.char, signIndex)
          if (startIndex !== -1) {
            signIndex = startIndex
            const endIndex = startIndex + sntDetails.char.length
            newText = newText.slice(0, startIndex) + char + newText.slice(endIndex)
          }
        }
      } else {
        const char = common.scoreLevel(details[p].score, details[p].char)
        const startIndex = newText.lastIndexOf(details[p].char, signIndex)
        if (startIndex !== -1) {
          signIndex = startIndex
          const endIndex = startIndex + details[p].char.length
          newText = newText.slice(0, startIndex) + char + newText.slice(endIndex)
        }
      }
    }
    newText = newText.replace(/\\/g, '/')
    return newText
  },

  /**
   * 去评测文本
   * fix issue：处理逻辑不对，已重写 by：rixin 2021/07/12
   */
  evaluate: (text) => {
    const t = text || ''
    if (t === '') return
    // 先处理大括号，拿到大括号内的文本
    const regBig = /\{.*?}/g
    const b = t.replace(regBig, (word) => {
      // 先去掉大括号，再取斜杠前面的内容
      return word.replace(/\{|}/g, '').split('/')[0]
    })
    // 再处理中括号，拿到中括号内的文本
    const regMedium = /(\[.*?])/g
    const m = b.replace(regMedium, (word) => {
      return word.replace(/\[|]/g, '').split('/')[0]
    })
    // 最后去掉奇怪的分隔符，并给每行进行换行
    return m.replace(/\\/g, '/').replace(/’/g, '\'').replace(/[“”]/g, '"').replace(/[#\r]/g, '')
      .replace(/\n/g, '<br />')
  },

  /**
   * 取评测文本
   * fix issue：处理逻辑不对，已部分重写 by：rixin 2021/07/12
   */
  getEvaluate: (t) => {
    const reg = /{.*?}/g // 先处理大括号
    const reg2 = /\[.*?]/g // 再处理中括号
    const d = t.replace(reg, (word) => {
      const arr = word.split('/')
      return arr[0]
    }).replace(reg2, (word) => {
      const arr = word.split('/')
      if (arr.length === 1) { // 对于中括号里包含大括号的情况，需判断分割后的数组长度
        return arr[0]
      }
      return arr[1]
    })
    const l = d.replace(/[\]}]/g, '')
    const u = l.replace(/’/g, '\'').replace(/[“”]/g, '"').replace(/[#\r]/g, '')
    const reg3 = /\(.*?\)/g
    return u.replace(reg3, (word) => {
      if (word.indexOf('s:') !== -1 || word.indexOf('t:') !== -1 || word.indexOf('g:') !== -1 || word.indexOf('c:') !== -1) {
        word = word.replace('.', ',')
      }
      return word
    })
  },

  /**
   * 取评测类型
   *
   */
  getCoreType: (t) => {
    let s
    switch (t) {
      case 0:
        s = 'unknow'
        break
      case 1:
        s = 'en.word.score'
        break
      case 2:
        s = 'en.sent.score'
        break
      case 3:
        s = 'en.pred.score'
        break
      case 4:
        s = 'en.choc.score'
        break
      case 5:
        s = 'en.pqan.score'
        break
      case 6:
        s = 'en.pict.score'
        break
      case 11:
        s = 'en.pcha.score'
        break
      case 12:
        s = 'en.alpha.score'
        break
      case 13:
        s = 'en.sent.rec'
        break
      case 15:
        s = 'en.retell.score'
        break
      case 16:
        s = 'en.pche.score'
        break
      default:
        s = 'unknow'
    }
    return s
  },

  /**
   * 取多发音参数
   *
   */
  getPhones: (t) => {
    const reg = /\{.*?\}/g
    const result = t.match(reg)
    if (result) {
      let str = '{'
      for (let i = 0, len = result.length; i < len; i += 1) {
        const a = result[i].replace(/[{}]/g, '')
        const arr = a.split('/')
        const property = arr[0].replace(/(^\s*)|(\s*$)/g, '')
        const val = arr[1].replace(/(^\s*)|(\s*$)/g, '')
        str += `"${property}":"${val}"`
        if (i < len - 1) {
          str += ','
        }
      }
      str += '}'
      return str
    }
    return null
  },

  /**
   * 笔试题标点前后去空格，去尾部标点
   *
   */
  writtenHandel: (str) => {
    if (!str) return ''
    str = str.trim().toLowerCase()
    return str.replace(/[ ]+/g, ' ').replace(/[ ]*,[ ]*/g, ',').replace(/[ ]*'[ ]*/g, '\'').replace(/[,.?!]$/g, '')
  },

  /**
   * 加序号
   *
   */
  addSerialNumber: (str) => {
    const b = str.replace(/(\n)+/g, '\n')
    const list = b.split('\n')
    for (let i = 0, len = list.length; i < len; i += 1) {
      if (list[i] !== '') {
        list[i] = `${i + 1}. ${list[i]}`
      }
    }
    return list.join('\n')
  },

  /**
   * 去HTML标签
   *
   */
  delHtmlTag: (str) => {
    const reg = /<\/?.+?\/?>/g
    return str.replace(reg, '')
  },

  /**
   *  评分颜色逻辑
   *
   */
  scoreLevel: (score, char) => {
    if (score < 60.0) {
      return `<span class="col-FF3C30 pointer">${char}<\\span>`
    }
    if (score < 85.0) {
      return `<span class="col-FFA516 pointer">${char}<\\span>`
    }
    return `<span class="col-1DC060 pointer">${char}<\\span>`
  },

  /**
   *  评分星级逻辑:得分百分比
   *  5%及以下，无星
   *  50%以下，一星
   *  50%-70%，二星
   *  70%-80%，三星
   *  80%-90%，四星
   *  90%-100%，五星
   */
  rateLevel: (score, totalScore) => {
    const total = totalScore || 100
    const per = score / total
    let rate
    if (per < 0.05) {
      rate = 0
    } else if (per < 0.5) {
      rate = 1
    } else if (per < 0.7) {
      rate = 2
    } else if (per < 0.8) {
      rate = 3
    } else if (per < 0.9) {
      rate = 4
    } else {
      rate = 5
    }
    return rate
  },

  partsOfSpeech: (pos) => {
    let name
    switch (pos) {
      case '0':
        name = 'n.'
        break
      case '1':
        name = 'v.'
        break
      case '2':
        name = 'vt.'
        break
      case '3':
        name = 'vi.'
        break
      case '4':
        name = 'adj.'
        break
      case '5':
        name = 'adv.'
        break
      case '6':
        name = 'prep.'
        break
      case '7':
        name = 'pron.'
        break
      case '8':
        name = 'conj.'
        break
      case '9':
        name = 'art.'
        break
      case '10':
        name = 'num.'
        break
      case '11':
        name = 'pl.'
        break
      case '12':
        name = 'modal v.'
        break
      case '13':
        name = 'aux.v.'
        break
      case '14':
        name = 'interj.'
        break
      case '15':
        name = 'det.'
        break
      case '16':
        name = 'abbr.'
        break
      case '17':
        name = 'pt.'
        break
      case '18':
        name = 'pp.'
        break
      case '19':
        name = 'vt. & vi.'
        break
      default:
        name = ''
    }
    return name
  },

  dateFormat: (fmt, date) => {
    let ret
    const opt = {
      'y+': date.getFullYear().toString(), // 年
      'M+': (date.getMonth() + 1).toString(), // 月
      'd+': date.getDate().toString(), // 日
      'h+': date.getHours().toString(), // 时
      'm+': date.getMinutes().toString(), // 分
      's+': date.getSeconds().toString() // 秒
    }
    Object.keys(opt).forEach((k) => {
      ret = new RegExp('(' + k + ')').exec(fmt)
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
      }
    })
    return fmt
  },

  timeFormat: (time) => {
    const t = parseInt(time, 10)
    let hour = 0
    let minute = 0
    let second = 0
    if (t > 0) {
      hour = Math.floor(t / (60 * 60))
      minute = Math.floor((t / 60) - (hour * 60))
      second = Math.floor(t - (hour * 60 * 60) - (minute * 60))
    }
    if (hour <= 9) hour = '0' + hour
    if (minute <= 9) minute = '0' + minute
    if (second <= 9) second = '0' + second
    return hour === '00' ? `${minute}:${second}` : `${hour}:${minute}:${second}`
  },

  timestampToTime: (time) => {
    const t = Math.ceil(time)
    let minute = 0
    let second = 0
    if (t > 0) {
      minute = Math.floor((t / 60))
      second = Math.floor(t - (minute * 60))
    }
    if (minute <= 9) minute = '0' + minute
    if (second <= 9) second = '0' + second
    return `${minute}:${second}`
  },

  /**
   * 麦克风检测
   *
   */
  micCheck: (resolve, reject) => {
    const constraints = {
      audio: !0
    }
    navigator.mediaDevices.getUserMedia(constraints)
      .then(() => {
        resolve()
      })
      .catch((err) => {
        reject(err)
      })
  },

  /**
   * 评测参数
   *
   */
  engineParams: (oralType, evalTime, self) => {
    const params = {}
    params.coreType = common.getCoreType(oralType.id)
    params.saveAudio = 1
    params.compress = 'raw'
    if (oralType.precision) params.precision = oralType.precision
    const measureRateScale = store.getters.measureRateScale
    if (measureRateScale && measureRateScale[oralType.id]) {
      params.rateScale = measureRateScale[oralType.id]
    } else if (oralType.rateScale) {
      params.rateScale = oralType.rateScale
    }
    let phones
    let keyArr
    let unkeyArr
    let answerTxtArr
    let pointsArr
    let keyValue
    let replaceStr
    switch (oralType.id) {
      case 1:
      case 2:
      case 3:
      case 12:
        if (!oralType.refText) {
          self.$error({
            title: '缺少参数',
            centered: true,
            onOk: () => {
              self.$router.go(-1)
            }
          })
          return false
        }
        params.refText = common.delHtmlTag(common.getEvaluate(oralType.refText))
        phones = common.getPhones(oralType.refText)
        if (phones) {
          params.phones = phones
        }
        break
      case 4:
        if (!oralType.key && !oralType.unkey) {
          self.$error({
            title: '缺少参数',
            centered: true,
            onOk: () => {
              self.$router.go(-1)
            }
          })
          return false
        }
        keyArr = common.delHtmlTag(common.getEvaluate(oralType.key)).split('\n')
        unkeyArr = common.delHtmlTag(common.getEvaluate(oralType.unkey)).split('\n')
        params.lm = []
        for (let i = 0, len = keyArr.length; i < len; i += 1) {
          const val = keyArr[i].trim()
          if (val) {
            const obj = { answer: 1, text: val }
            params.lm.push(obj)
          }
        }
        for (let i = 0, len = unkeyArr.length; i < len; i += 1) {
          const val = unkeyArr[i].trim()
          if (val) {
            const obj = { answer: 0, text: val }
            params.lm.push(obj)
          }
        }
        break
      case 5:
        if (!oralType.answerTxt || !oralType.key) {
          self.$error({
            title: '缺少参数',
            centered: true,
            onOk: () => {
              self.$router.go(-1)
            }
          })
          return false
        }
        answerTxtArr = common.delHtmlTag(common.getEvaluate(oralType.answerTxt)).split('\n')
        params.lm = []
        for (let i = 0, len = answerTxtArr.length; i < len; i += 1) {
          const val = answerTxtArr[i].trim()
          if (val) {
            const obj = { text: val }
            params.lm.push(obj)
          }
        }
        keyArr = common.delHtmlTag(common.getEvaluate(oralType.key)).split('\n')
        params.key = []
        for (let i = 0, len = keyArr.length; i < len; i += 1) {
          const val = keyArr[i].trim()
          if (val) {
            const obj = { text: val }
            params.key.push(obj)
          }
        }
        phones = common.getPhones(oralType.answerTxt)
        if (phones) {
          params.phones = phones
        }
        break
      case 6:
      case 11:
      case 13:
      case 15:
        if (!oralType.answerTxt) {
          self.$error({
            title: '缺少参数',
            centered: true,
            onOk: () => {
              self.$router.go(-1)
            }
          })
          return false
        }
        answerTxtArr = common.delHtmlTag(common.getEvaluate(oralType.answerTxt)).split('\n')
        params.lm = []
        for (let i = 0, len = answerTxtArr.length; i < len; i += 1) {
          const val = answerTxtArr[i].trim()
          if (val) {
            const obj = { text: val }
            params.lm.push(obj)
          }
        }
        if (oralType.grade) params.grade = oralType.grade
        if (oralType.points) {
          pointsArr = common.delHtmlTag(common.getEvaluate(oralType.points)).split('\n')
          params.points = []
          for (let i = 0, len = pointsArr.length; i < len; i += 1) {
            const val = pointsArr[i].trim()
            if (val) {
              const obj = { text: val }
              params.points.push(obj)
            }
          }
        }
        phones = common.getPhones(oralType.answerTxt)
        if (phones) {
          params.phones = phones
        }
        break
      case 16:
        if (!oralType.answerTxt || !oralType.key || !oralType.unkey) {
          self.$error({
            title: '缺少参数',
            centered: true,
            onOk: () => {
              self.$router.go(-1)
            }
          })
          return false
        }
        params.pronScale = 1
        answerTxtArr = common.delHtmlTag(common.getEvaluate(oralType.answerTxt)).split(/\n+/)
        keyArr = common.delHtmlTag(common.getEvaluate(oralType.key)).split(/\n+/)
        keyValue = keyArr[0].toLowerCase()
        params.key = []
        replaceStr = []
        for (let i = 0, len = keyArr.length; i < len; i += 1) {
          if (keyArr[i] !== '') {
            replaceStr.push(keyArr[i])
            const obj = { text: keyArr[i] }
            params.key.push(obj)
          }
        }
        unkeyArr = common.delHtmlTag(common.getEvaluate(oralType.unkey)).split(/\n+/)
        params.unkey = []
        for (let i = 0, len = unkeyArr.length; i < len; i += 1) {
          if (unkeyArr[i] !== '') {
            replaceStr.push(unkeyArr[i])
            const obj = { text: unkeyArr[i] }
            params.unkey.push(obj)
          }
        }
        replaceStr = replaceStr.join('|')
        replaceStr = `(${replaceStr})`
        params.lm = []
        for (let i = 0, len = answerTxtArr.length; i < len; i += 1) {
          const t = answerTxtArr[i].toLowerCase()
          const index = t.indexOf(keyValue)
          const endIndex = index + keyValue.length
          if (index !== -1) {
            answerTxtArr[i] = answerTxtArr[i].slice(0, index) + replaceStr + answerTxtArr[i].slice(endIndex)
          }
          const obj = { text: answerTxtArr[i] }
          params.lm.push(obj)
        }
        phones = common.getPhones(oralType.answerTxt)
        if (phones) {
          params.phones = phones
        }
        break
      default:
        self.$error({
          title: '未知题型',
          centered: true,
          onOk: () => {
            self.$router.go(-1)
          }
        })
        return false
    }
    params.attachAudioUrl = 1
    params.audioUrlScheme = 'https'
    if (evalTime) {
      params.evalTime = evalTime * 1000 * 1.5
    } else {
      params.evalTime = evalTime
    }
    return params
  },

  /**
   * 评测引擎初始化
   *
   */
  engineEvaluates: (userId, callback, bufferCallback) => {
    const data = {
      state: 1,
      note: 'success'
    }
    return new window.EngineEvaluat({
      engineConnectTimeOut() {
        data.state = 0
        data.note = '网络不稳定，请稍后重试'
        callback(data)
      },
      micForbidCallback() {
        data.state = 0
        data.micInit = true
        data.note = '麦克风初始化失败，将无法进行录音,请检查麦克风'
        callback(data)
      },
      noNetwork() {
        data.state = 0
        data.note = '网络连接失败，请检查网络'
        callback(data)
      },
      noWebsocketAddress() {
        data.state = 0
        data.note = '初始化失败，将无法进行录音，请检查网络'
        callback(data)
      },
      micAllowCallback() {
        data.state = 2
        data.note = 'micAllow'
        callback(data)
      },
      engineFirstInitDone() {
        data.state = 1
        data.note = '初次初始化成功'
        callback(data)
      },
      engineBackResultFail(msg) {
        data.state = 3
        const message = JSON.parse(msg)
        data.errId = message.errId
        data.note = `评测失败${message.errMsg}${message.errId}(${(navigator.connection.downlink * 1024) / 8}kb/s)`
        callback(data)
      },
      audioDataCallback(data, isLast) {
        bufferCallback(data, isLast)
      },
      coreTimeout: 60000, // 连接服务器超时时间
      serverTimeout: 60000, // 返回测评结果超时时间
      connectTimeout: 30000, // 建立网络连接的超时时间
      applicationId: 'a227',
      protocolHeader: 'ws', // 配置websocket协议
      logIsOpen: false, // 是否开启日志
      engineLinksAddress: ['api.cloud.ssapi.cn', 'gate-01.api.cloud.ssapi.cn', 'gate-02.api.cloud.ssapi.cn', 'gate-03.api.cloud.ssapi.cn'],
      sig: userId,
      userId
    })
  },

  detectZoom: () => {
    let ratio = 0
    if (window.devicePixelRatio !== undefined) {
      ratio = window.devicePixelRatio
    } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
      ratio = window.outerWidth / window.innerWidth
    }
    return ratio
  },

  objSort: (obj) => {
    const arr = Object.entries(obj)

    function compare(index) {
      return (a, b) => {
        a = a[index]
        b = b[index]
        if (a < b) return -1
        if (a > b) return 1
        return 0
      }
    }

    arr.sort(compare(0))
    let str = ''
    for (let i = 0, len = arr.length; i < len; i += 1) {
      str += `${arr[i][0]}=${arr[i][1]}`
      if (i !== len - 1) {
        str += '&'
      }
    }
    return str
  },

  bufToFile: (buffer, fileName) => new File([buffer], fileName),

  /**
   * 单词排序(升序)
   */
  quickOrder: (arr, property) => {
    const left = []
    const right = []
    if (arr.length <= 1) {
      return arr
    }
    const first = arr.splice(0, 1)
    for (let i = 0, len = arr.length; i < len; i += 1) {
      if (typeof first[0][property] !== 'number') first[0][property] = first[0][property].toLowerCase()
      if (typeof arr[i][property] !== 'number') arr[i][property] = arr[i][property].toLowerCase()
      if (first[0][property] > arr[i][property]) {
        left.push(arr[i])
      } else {
        right.push(arr[i])
      }
    }
    return common.quickOrder(left, property).concat(first, common.quickOrder(right, property))
  },

  isJSON: (str) => {
    if (typeof str === 'string') {
      try {
        const obj = JSON.parse(str)
        return !!(typeof obj === 'object' && obj)
      } catch (e) {
        return false
      }
    } else {
      return false
    }
  },

  /**
   * guid
   */
  generateGUID: () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0 // eslint-disable-line
    const v = c === 'x' ? r : (r & 0x3 | 0x8) // eslint-disable-line
    return v.toString(16)
  }),

  /**
   * 随机数
   */
  getRandom: (min, max) => {
    const range = max - min
    const rand = Math.random()
    return min + Math.round(rand * range)
  },

  urlToFile: (url) => new Promise((res, rej) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      const content = xhr.response
      const blob = new Blob([content])
      const file = new File([blob], '1.wav')
      res(file)
    }
    xhr.onerror = (err) => {
      rej(err)
    }
    xhr.send()
  }),

  getQueryVariable: (variable) => {
    const query = window.location.search.substring(1)
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i += 1) {
      const pair = vars[i].split('=')
      if (pair[0] === variable) return pair[1]
    }
    return ''
  },

  setStorage: (name, val) => {
    window.parent.postMessage({
      name: 'setStorage',
      storageName: name,
      data: val
    }, '*')
  },

  removeStorage: (name) => {
    window.parent.postMessage({
      name: 'removeStorage',
      storageName: name
    }, '*')
  },

  clearStorage: () => {
    window.parent.postMessage({
      name: 'clearStorage'
    }, '*')
  },

  enterFullScreen: () => {
    window.parent.postMessage({
      name: 'enter-full-screen'
    }, '*')
  },

  leaveFullScreen: () => {
    window.parent.postMessage({
      name: 'leave-full-screen'
    }, '*')
  },
  /**
   * 设置参考答案
   * @param oralType 评测内容
   * @param self this对象
   * @returns {string|boolean} 参考答案
   */
  setReferAnswer: (oralType, self) => {
    let refText = ''
    switch (oralType.id) {
      case 1:
      case 2:
      case 3:
      case 12:
        if (!oralType.refText) {
          self.$error({
            title: '缺少参数',
            centered: true,
            onOk: () => {
              self.$router.go(-1)
            }
          })
          return false
        }
        refText = common.getEvaluate(oralType.refText).replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>')
        break
      case 4:
        if (!oralType.key && !oralType.unkey) {
          self.$error({
            title: '缺少参数',
            centered: true,
            onOk: () => {
              self.$router.go(-1)
            }
          })
          return false
        }
        break
      case 5:
      case 6:
      case 11:
      case 13:
      case 15:
      case 16:
      case 100:
        if (!oralType.answerTxt) {
          self.$error({
            title: '缺少参数',
            centered: true,
            onOk: () => {
              self.$router.go(-1)
            }
          })
          return false
        }
        refText = common.cutOut(common.getEvaluate(oralType.answerTxt)).replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>')
        break
      default:
        self.$error({
          title: '未知题型',
          centered: true,
          onOk: () => {
            self.$router.go(-1)
          }
        })
        return false
    }
    return refText
  }
}
