import request from '@/utils/request'

/**
 * 获取首页模块列表
 * @data {*} data
 */
export function coverModuleList(data) {
  return request({
    url: '/apiv2/aiClassroom/module/findCoverModuleList',
    method: 'post',
    data
  })
}

/**
 * 根据父ID获取子模块列表
 * @data {*} data
 */
export function moduleListByPid(data) {
  return request({
    url: '/apiv2/aiClassroom/module/findModuleListByPid',
    method: 'post',
    data
  })
}

/**
 * 获取本地专版模块列表
 * @data {*} data
 */
export function especialList(data) {
  return request({
    url: '/apiv2/aiClassroom/module/findEspecialList',
    method: 'post',
    data
  })
}

/* ****************************************************** */

/**
 * 获取评测的松紧度
 * @data {*} data
 */
export function measureTightness(data) {
  return request({
    url: '/global/measureTightness',
    method: 'post',
    data
  })
}

/**
 * 获取给教学系统的阿里云Oss相关参数
 * @data {*} data
 */
export function userOssParam(data) {
  return request({
    url: '/apiv2/aiClassroom/common/getOssParam',
    method: 'post',
    data
  })
}

/**
 * 根据pid获取模块列表
 * @data {*} data
 */
// getModulesByPid
export function modulesByPid(data) {
  return request({
    url: '/entrance/getModulesByPid',
    method: 'post',
    data
  })
}

/**
 * 获取模块下的试题列表
 * @data {*} data
 */
export function findPaperList(data) {
  return request({
    url: '/index/findPaperList',
    method: 'post',
    data
  })
}

/**
 * 通过教师Id获取班级列表
 * @data {*} data
 */
export function clazzListByTeacherId(data) {
  return request({
    url: '/apiv2/aiClassroom/clazz/getListByTeacherId',
    method: 'post',
    data
  })
}

/**
 * 根据班级ID获取学生列表
 * @data {*} data
 */
export function studentListByClazzId(data) {
  return request({
    url: '/apiv2/aiClassroom/student/getListByClazzId',
    method: 'post',
    data
  })
}

/**
 * 根据班级ID获取学生分组
 * @data {*} data
 */
export function studentGroupByClazzId(data) {
  return request({
    url: '/apiv2/aiClassroom/student/getUserGroupListWithStudent',
    method: 'post',
    data
  })
}

/**
 * 读取文件
 * @data {*} data
 */
export function readFile(url) {
  return request({
    url: url
  })
}

/**
 * 创建课堂并返回课堂信息
 * @data {*} data
 */
export function classInfoByTeacherId(data) {
  return request({
    url: '/apiv2/aiClassroom/classroom/save',
    method: 'post',
    data
  })
}

