<template>
  <div class="footer">
    <div class="hover-content" @click="getBack">
      <img class="left-icon" :src="require('@/assets/common/arrow_left_big.png')" alt="">
      <span class="text">返回</span>
    </div>
    <a-popover content="字体调整" overlay-class-name="popover-tips-text">
      <div class="hover-content font-size">
        <FontSize v-if="showSize" :font-size="size" />
      </div>
    </a-popover>
    <slot name="custom" class="custom" />
    <div v-if="showHistory" class="answer-history" @click="checkAnswer">
      <img class="answer-history-icon" :src="require('@/assets/common/history.png')" alt="">
      <div class="text">作答历史</div>
    </div>
  </div>
</template>

<script>
import FontSize from '@/components/common/FontSize'

export default {
  name: 'Footer',
  components: {
    FontSize
  },
  props: {
    // 显示/隐藏 调整字体大小组件
    showSize: {
      type: Boolean,
      default: () => true
    },
    // 显示/隐藏 调整字体大小组件
    showHistory: {
      type: Boolean,
      default: () => false
    },
    // 退出拦截
    exitIntercept: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {}
  },
  computed: {
    size() {
      return Number(this.$store.getters.size)
    }
  },
  methods: {
    getBack() {
      // this.$router.go(-1)
      if (this.exitIntercept) {
        this.$emit('goBack')
      } else {
        this.$router.back()
      }
    },
    // 查看作答历史
    checkAnswer() {
      this.$emit('checkAnswer')
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: calc(100% - 2px);
  height: 64px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #FFFFFF;
  border-top: 1px solid #EEEEEE;
  user-select: none;
  box-shadow: 0 -5px 20px 0 rgba(0, 0, 0, 0.03);

  .left-icon {
    width: 20px;
    height: 20px;
  }

  .text {
    font-size: 17px;
    font-weight: 600;
    color: #333333;
    margin-left: 5px;
  }

  .font-size {
    padding: 0;
  }
  .custom{
    flex: 1;
  }
  .answer-history{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 36px;
    margin-right: 14px;
    .answer-history-icon{
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
    .text{
      font-size: 15px;
      line-height: 21px;
    }
  }
}
</style>
