import store from '../store'
import { userOssParam } from '@/api/common'
import { Modal } from 'ant-design-vue'

/**
 * 获取阿里云Oss相关参数
 * 参数：
 *  mainBucketName          学生上传其他文件的存储桶名称
 *  answerInfoBucketName	  学生作答info文件的存储桶名称
 *  answerMediaBucketName	  学生作答媒体文件的存储桶名称
 *  endpoint	              OSS服务地址
 *  ossRefreshUrl	          面向客户端阿里云OSS SDK的OSS凭证刷新接口地址
 *  credentials	            凭证
 *    accessKeyId	            访问密钥标识
 *    accessKeySecret	        访问密钥
 *    securityToken           安全令牌
 *    expiration	            凭证过期时间
 *    expiredTime	            凭证过期时间，UNIX 时间戳
 *    ossProvider	            Oss 厂商 (0=阿里云Oss)
 * 备注：
 *  学生作答info文件的存储桶（answerInfoBucketName）中文件 ObjectKey 的命名规则: userId  +  "/" + 13位的毫秒时间戳 + ".info"
 *  学生作答媒体文件的存储桶（answerMediaBucketName）中文件 ObjectKey 的命名规则: (userId_前四位) +  "/" + recordId.文件后缀名
 */
const getOssParam = function() {
  let ossParam = store.getters.ossParam
  return new Promise((resolve, reject) => {
    if (!ossParam || !ossParam.expiredTime || ossParam.expiredTime - (Date.parse(new Date()) / 1000) < 60) {
      userOssParam({ needCredentials: true }).then((res) => {
        ossParam = { ...res.data, ...res.data.credentials }
        store.dispatch('user/setAccessKey', ossParam)
        resolve(ossParam)
      }).catch((err) => {
        Modal.confirm({
          content: '网络错误，获取key失败',
          okText: '重新获取',
          cancelText: '取消',
          onOk() {
            getOssParam()
          },
          onCancel() {
            reject(err)
          }
        })
      })
    } else {
      resolve(ossParam)
    }
  })
}

/**
 * 获取阿里云client配置
 * @param fileType  上传文件的类型，不同类型使用不同的地址
 * @param timeout  超时时间, 默认为 60000
 * @returns {Promise<Client>}
 */
const getOssClient = async(fileType = 'answerMediaBucketName', timeout = 60000) => {
  const OSS = require('ali-oss')
  let ossParam = null
  // 获取 oss 相关参数
  try {
    ossParam = await getOssParam()
  } catch (error) {
    console.log(error)
    return
  }
  const ossConfig = {
    region: 'oss-cn-shenzhen',
    accessKeyId: ossParam.credentials.accessKeyId,
    accessKeySecret: ossParam.credentials.accessKeySecret,
    stsToken: ossParam.credentials.securityToken,
    bucket: ossParam[fileType],
    timeout
  }
  return await new OSS(ossConfig)
}

/**
 * 完整上传 媒体文件
 * @param filePath 文件路径
 * @param bufferArr  文件
 * @param fileType  上传文件的类型，不同类型使用不同的地址
 * @param timeout 超时时间, 默认为 15000
 * @returns {Promise<*>}
 */
const putMediaFile = async(filePath, bufferArr, fileType, timeout = 60000) => {
  try {
    const client = await getOssClient(fileType, timeout)
    const result = await client.put(filePath, bufferArr)
    console.log('完整上传文件成功', result)
    return result
  } catch (e) {
    console.error('完整上传文件失败', e)
    console.error('上传路径：' + filePath)
    throw e
  }
}
/**
 * 分片上传
 * @param filePath 文件路径
 * @param bufferArr 文件
 * @param fileType  上传文件的类型，不同类型使用不同的地址
 * @param timeout 超时时间, 默认为 15000
 * @param parallel 并发上传的分片个数，默认值为 5。
 * @param partSize 指定上传的每个分片的大小，范围为100 KB~5 GB。单个分片默认大小为 0.5 * 1024 * 1024（ 0.5 MB即 512 KB）
 * @returns {Promise<*>}
 */
const multipartUploadFile = async(filePath, bufferArr, fileType, timeout = 15000, parallel = 5, partSize = 524288) => {
  try {
    const multipartConfig = {
      parallel,
      partSize
    }
    const client = await getOssClient(fileType, timeout)
    const result = await client.multipartUpload(filePath, bufferArr, multipartConfig)
    console.log('分片上传文件成功', result)
    // 此处需要补充url属性，与单文件上传保证结构一致性，后续的代码会用到
    try {
      const { status, requestUrls } = result.res
      if (status === 200 && requestUrls && requestUrls.length) {
        const urlData = new URL(result.res.requestUrls[0])
        result.url = urlData.origin + urlData.pathname
      }
    } catch (e) {
      console.error('处理分片结果异常', e)
    }
    console.log('分片上传文件调整结果', result)
    return result
  } catch (e) {
    console.error('分片上传文件失败', e)
    console.error('上传路径：' + filePath)
    throw e
  }
}

/**
 * 完整上传 info文件
 * @param filePath 文件路径
 * @param data  info数据
 * @param fileType  上传文件的类型，不同类型使用不同的地址
 * @param timeout 超时时间, 默认为 15000
 * @returns {Promise<*>}
 */
const putInfoFile = async(filePath, data, fileType, timeout = 60000) => {
  try {
    const client = await getOssClient(fileType, timeout)
    const result = await client.put(filePath, new Buffer(data))
    console.log('完整上传文件成功', result)
    return result
  } catch (e) {
    console.error('完整上传文件失败', e)
    console.error('上传路径：' + filePath)
    throw e
  }
}

export default {
  getOssClient,
  putMediaFile,
  putInfoFile,
  multipartUploadFile
}
