<template>
  <div :class="['unfold', direction]" @click="unfoldUnit">
    <template v-if="direction === 'left'">
      <template>
        <img v-if="unfold" class="no-hover arrow-img" :src="require('@/assets/common/arrow_left.png')" alt="">
        <img v-else class="no-hover arrow-img" :src="require('@/assets/common/arrow_right_active.png')" alt="">
      </template>
      <template>
        <img v-if="unfold" class="hover arrow-img" :src="require('@/assets/common/small_arrow_left_active.png')" alt="">
        <img v-else class="hover arrow-img" :src="require('@/assets/common/small_arrow_right_active.png')" alt="">
        <div class="hover unfold-text">点击{{ unfold ? '收起' : '展开' }}</div>
      </template>
      <img class="notch-top" :src="require('@/assets/common/notch_bottom_left.png')" alt="">
      <img class="notch-bottom" :src="require('@/assets/common/notch_top_left.png')" alt="">
    </template>
    <template v-if="direction === 'right'">
      <template>
        <img v-if="unfold" class="no-hover arrow-img" :src="require('@/assets/common/arrow_right.png')" alt="">
        <img v-else class="no-hover arrow-img" :src="require('@/assets/common/arrow_left_active.png')" alt="">
      </template>
      <template>
        <img
          v-if="unfold"
          class="hover arrow-img"
          :src="require('@/assets/common/small_arrow_right_active.png')"
          alt=""
        >
        <img v-else class="hover arrow-img" :src="require('@/assets/common/small_arrow_left_active.png')" alt="">
        <div class="hover unfold-text">点击{{ unfold ? '收起' : '展开' }}</div>
      </template>
      <img class="notch-top" :src="require('@/assets/common/notch_bottom_right.png')" alt="">
      <img class="notch-bottom" :src="require('@/assets/common/notch_top_right.png')" alt="">
    </template>
  </div>
</template>

<script>
export default {
  name: 'Unfold',
  props: {
    unfold: {
      type: Boolean,
      default: () => true
    },
    direction: {
      type: String,
      default: () => 'left'
    }
  },
  methods: {
    // 展开
    unfoldUnit() {
      this.$emit('update:unfold', !this.unfold)
    }
  }
}
</script>

<style lang="scss" scoped>
.unfold {
  width: 26px;
  height: 74px;
  position: absolute;
  bottom: 70px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-size: 11px;
  line-height: 14px;

  .no-hover {
    &.arrow-img {
      width: 6px;
      height: 11px;
    }
  }

  .hover {
    display: none;
    color: #1976FF;

    &.arrow-img {
      width: 4px;
      height: 7px;
      margin-right: 4px;
    }

    &.unfold-text {
      width: 12px;
      user-select: none;
    }
  }

  &:hover {
    .no-hover {
      display: none;
    }

    .hover {
      display: block;
    }
  }

  &.left {
    right: -26px;
    border-radius: 0 12px 12px 0;
    box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.16);

    .notch-bottom {
      left: 0;
      bottom: -10px;
    }

    .notch-top {
      left: 0;
      top: -10px;
    }
  }

  &.right {
    left: -26px;
    border-radius: 12px 0 0 12px;
    box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.16);

    .notch-bottom {
      right: 0;
    }

    .notch-top {
      right: 0;
    }
  }

  .notch-bottom {
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: -10px;
  }

  .notch-top {
    width: 10px;
    height: 10px;
    position: absolute;
    top: -10px;
  }
}
</style>
