import { logout } from '@/api/login'
import * as storage from '@/utils/auth'
import * as types from '../mutation-types'

const dayjs = require('dayjs')
import { classInfoByTeacherId } from '@/api/common'

const state = {
  token: storage.getToken(),
  userId: storage.getUserId(),
  userName: storage.getUserName(),
  phone: storage.getPhone(),
  userInfo: storage.getUserInfo(),
  classData: storage.getClassData(),
  evaluateUserId: storage.getEvaluateUserId() || '', // 评测ID，type=1时是用户ID，否则是 guest（游客）,
  // 阿里云 OSS 参数
  accessKeyId: storage.getAccessKeyId(),
  bucketName: storage.getBucketName(),
  accessKeySecret: storage.getAccessKeySecret(),
  ossParam: storage.getOssParam(),

  waitingList: storage.getWaitingList(), // 待答名单
  activeAnswer: storage.getActiveAnswer(), // 当前作答学生
  classroom: storage.getClassRoom(), // 课堂id
  pkSelectBook: storage.getPkSelectBook() // pk选择的书
}

const mutations = {
  // reset all
  [types.SMART_TEACHING_SYSTEM_RESET_ALL](state, data) {
    const remove = ['token', 'userId', 'userName', 'phone', 'userInfo', 'accessKeyId', 'bucketName', 'accessKeySecret', 'ossParam', 'classData', 'evaluateUserId']
    for (const key in state) {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        if (remove.indexOf(key) > -1) {
          state[key] = data
        }
      }
    }
    storage.removeAll()
  },
  // token
  [types.SMART_TEACHING_SYSTEM_TOKEN](state, data) {
    state.token = data
    data === '' ? storage.removeToken() : storage.setToken(data)
  },
  // userId
  [types.SMART_TEACHING_SYSTEM_USER_ID](state, data) {
    state.userId = data
    data === '' ? storage.removeUserId() : storage.setUserId(data)
  },
  // userName
  [types.SMART_TEACHING_SYSTEM_USER_NAME](state, data) {
    state.userName = data
    data === '' ? storage.removeUserName() : storage.setUserName(data)
  },
  // phone
  [types.SMART_TEACHING_SYSTEM_PHONE](state, data) {
    state.phone = data
    data === '' ? storage.removePhone() : storage.setPhone(data)
  },
  // userInfo
  [types.SMART_TEACHING_SYSTEM_USER_INFO](state, data) {
    state.userInfo = data
    data === '' ? storage.removeUserInfo() : storage.setUserInfo(data)
  },
  // classData
  [types.SMART_TEACHING_SYSTEM_CLASS_DATA](state, data) {
    state.classData = data
    data === '' ? storage.removeClassData() : storage.setClassData(data)
    // 切换班级后需要清空待答名单和当前作答人 和 课堂id
    state.waitingList = []
    state.activeAnswer = {}
    storage.removeActiveAnswer()
    storage.removeWaitingList()
    storage.removeClassRoom()
  },
  // waitingList
  [types.SMART_TEACHING_SYSTEM_WAITING_LIST](state, data) {
    state.waitingList = data
    storage.setWaitingList(data)
  },
  // activeAnswer
  [types.SMART_TEACHING_SYSTEM_ACTIVE_ANSWER](state, data) {
    state.activeAnswer = data
    storage.setActiveAnswer(data)
  },
  // classroom
  [types.SMART_TEACHING_SYSTEM_CLASSROOM](state, data) {
    state.classroom = data
    storage.setClassRoom(data)
  },
  // pkSelectBook
  [types.SMART_TEACHING_SYSTEM_PK_SELECT_BOOK](state, data) {
    state.pkSelectBook = data
    storage.setPkSelectBook(data)
  },
  // evaluateUserId
  [types.SMART_TEACHING_SYSTEM_EVALUATE_USER_ID](state, data) {
    state.evaluateUserId = data
    data === '' ? storage.removeEvaluateUserId() : storage.setEvaluateUserId(data)
  },
  // accessKeyId
  [types.SMART_TEACHING_SYSTEM_ACCESS_KEY_ID](state, data) {
    state.accessKeyId = data
    data === '' ? storage.removeAccessKeyId() : storage.setAccessKeyId(data)
  },
  // bucketName
  [types.SMART_TEACHING_SYSTEM_BUCKET_NAME](state, data) {
    state.bucketName = data
    data === '' ? storage.removeBucketName() : storage.setBucketName(data)
  },
  // accessKeySecret
  [types.SMART_TEACHING_SYSTEM_ACCESS_KEY_SECRET](state, data) {
    state.accessKeySecret = data
    data === '' ? storage.removeAccessKeySecret() : storage.setAccessKeySecret(data)
  },
  // ossParam
  [types.SMART_TEACHING_SYSTEM_OSS_PARAM](state, data) {
    state.ossParam = data
    data === '' ? storage.removeOssParam() : storage.setOssParam(data)
  }
}

const actions = {
  // 登出
  logout({ dispatch }) {
    return new Promise((resolve, reject) => {
      logout({}).then((res) => {
        if (res['state'] !== 11) {
          console.error(res['message'])
        }
        dispatch('resetAll')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 清除所有信息
  resetAll({ commit }) {
    return new Promise(resolve => {
      commit(types.SMART_TEACHING_SYSTEM_RESET_ALL, '')
      resolve('清除成功')
    })
  },
  setWaitingList({ commit }, data) {
    commit(types.SMART_TEACHING_SYSTEM_WAITING_LIST, data)
  },
  setActiveAnswer({ commit }, data) {
    commit(types.SMART_TEACHING_SYSTEM_ACTIVE_ANSWER, data)
  },
  setPkSelectBook({ commit }, data) {
    commit(types.SMART_TEACHING_SYSTEM_PK_SELECT_BOOK, data)
  },
  setClassroom({ commit, state }) {
    return new Promise((resolve, reject) => {
      classInfoByTeacherId({
        clazzId: state.classData.id,
        teacherId: state.userId,
        startTime: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
      }).then(res => {
        const { state, data } = res
        if (state !== 11) return
        commit(types.SMART_TEACHING_SYSTEM_CLASSROOM, data)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  // 设置登录信息
  setLoginInfo({ commit }, data) {
    return new Promise(resolve => {
      commit(types.SMART_TEACHING_SYSTEM_USER_ID, data.id)
      commit(types.SMART_TEACHING_SYSTEM_TOKEN, data.authToken)
      commit(types.SMART_TEACHING_SYSTEM_USER_NAME, data.name)
      commit(types.SMART_TEACHING_SYSTEM_PHONE, data.phone)
      commit(types.SMART_TEACHING_SYSTEM_USER_INFO, data)
      commit(types.SMART_TEACHING_SYSTEM_EVALUATE_USER_ID, data.id)
      resolve(data)
    })
  },
  /**
   * 获取Oss参数
   * @param commit
   * @param {object} data
   */
  setAccessKey({ commit }, data) {
    const { bucketName, credentials } = data
    const { accessKeyId, accessKeySecret } = credentials
    commit(types.SMART_TEACHING_SYSTEM_ACCESS_KEY_ID, accessKeyId)
    commit(types.SMART_TEACHING_SYSTEM_ACCESS_KEY_SECRET, accessKeySecret)
    commit(types.SMART_TEACHING_SYSTEM_BUCKET_NAME, bucketName)
    commit(types.SMART_TEACHING_SYSTEM_OSS_PARAM, data)
  },
  /**
   * 清除用户OSS参数信息
   * @param commit
   */
  clearAccessKey({ commit }) {
    commit(types.SMART_TEACHING_SYSTEM_ACCESS_KEY_ID, '')
    commit(types.SMART_TEACHING_SYSTEM_ACCESS_KEY_SECRET, '')
    commit(types.SMART_TEACHING_SYSTEM_BUCKET_NAME, '')
    commit(types.SMART_TEACHING_SYSTEM_OSS_PARAM, '')
  },
  /**
   * 设置班级信息
   * @param commit
   * @param data
   */
  setClassData({ commit }, data) {
    commit(types.SMART_TEACHING_SYSTEM_CLASS_DATA, data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
