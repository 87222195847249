// 系统名
export const title = 'A.I.英语听说课堂'
// 页脚公司名
export const loginFooter = '中科启云（广州）数字科技有限公司  1.0.1'
// 版本号
export const version = '3.0.0'
/* 1:西柚 */
export const belong = 1

export const masters = '_Sts100#@'

// 播放顺序
export const playOrder = {
  0: 'sequential', // 顺序
  1: 'random' // 随机
}

// 播放模式
export const playMode = {
  0: 'manual', // 手动
  1: 'auto' // 字段
}

// 年级数据map
export const gradeData = {
  0: '六年级',
  1: '七年级',
  2: '八年级',
  3: '九年级',
  4: '高一',
  5: '高二',
  6: '高三',
  7: '初中',
  8: '高中',
  9: '小学',
  100: '幼儿园',
  101: '一年级',
  102: '二年级',
  103: '三年级',
  104: '四年级',
  105: '五年级'
}

// 模块分类
export const category = {
  1: '普通模块',
  2: '模考类型模块',
  3: '听说专区',
  4: '读写专区',
  5: '热门推荐',
  6: '教辅',
  7: '内容平台素材'
}
// 模块类型
export const moduleType = {
  1: '听说读写',
  4: '单词',
  5: '音标',
  6: '课文听读',
  8: '扩展练习',
  9: '趣味配音',
  11: '我的教辅',
  12: '同步课时练',
  13: '资源中心',
  14: '假期作业',
  15: '同步诗词',
  16: '普通诗词'
}

// 首页模块背景样式类名
export const bgClassList = {
  'word': 'bg-FF8436-FF9F61',
  'text': 'bg-4772FF-6286FF',
  'auxiliary': 'bg-0E8FA3-2ABCD2',
  'special': 'bg-6C6EFF-8688FF',
  'pk': 'bg-FF3767-FF6086',
  'work': 'bg-0979D7-2F9CF7',
  'exam': 'bg-FB4A4F-FF6B6F',
  'condition': 'bg-A146FC-B265FF',
  'setting': 'bg-6375AF-91A1D3',
  'else': 'bg-09904B-25BF70'
}
// 作答情况类型
export const answerType = {
  1: '单词',
  2: '单项填空、多项填空、听说混选题、听说填空',
  3: '词汇运用、听说选择题',
  4: '课文听读、朗读、跟读、复述'
}
// 模式列表
export const modeList = [
  { id: 1, text: '朗读' },
  { id: 2, text: '跟读' },
  { id: 3, text: '复述' }
]

// 模块列表
export const moduleList = [
  {
    free: 1,
    gradeTypeId: 8,
    id: '111',
    src: require('@/assets/home/home_special.png'),
    name: '本地专版',
    toPublic: 1,
    type: 12,
    path: 'SpecialBookList'
  }
  // {
  //   free: 1,
  //   gradeTypeId: 8,
  //   id: '',
  //   mainPhoto: require('@/assets/home/home_pk.png'),
  //   name: '单词PK',
  //   toPublic: 1,
  //   type: 13,
  //   path: 'CreatePk',
  //   query: {
  //     origin: 'index'
  //   },
  //   classType: 'pk'
  // },
  // {
  //   free: 1,
  //   gradeTypeId: 8,
  //   id: '112',
  //   mainPhoto: require('@/assets/home/home_work.png'),
  //   name: '作业',
  //   toPublic: 1,
  //   type: 12,
  //   path: '',
  //   classType: 'work'
  // },
  // {
  //   free: 1,
  //   gradeTypeId: 8,
  //   id: '113',
  //   mainPhoto: require('@/assets/home/home_exam.png'),
  //   name: '考试',
  //   toPublic: 1,
  //   type: 12,
  //   path: 'PkContainer',
  //   classType: 'exam'
  // },
  // {
  //   free: 1,
  //   gradeTypeId: 8,
  //   id: '114',
  //   mainPhoto: require('@/assets/home/home_case.png'),
  //   name: '学情',
  //   toPublic: 1,
  //   type: 12,
  //   path: '',
  //   classType: 'condition'
  // },
  // {
  //   free: 0,
  //   gradeTypeId: 8,
  //   id: '115',
  //   mainPhoto: require('@/assets/home/home_setting.png'),
  //   name: '班级设置',
  //   toPublic: 1,
  //   type: 12,
  //   path: '',
  //   classType: 'setting'
  // },
  // {
  //   free: 0,
  //   gradeTypeId: 8,
  //   id: '116',
  //   mainPhoto: '',
  //   name: '其他',
  //   toPublic: 1,
  //   type: 12,
  //   path: '',
  //   classType: 'else'
  // }
]
// 流程类型表
export const ProcessTypeEnum = {
  1: { key: 'PLAY_QUESTION_STEM', name: '播放题干' },
  2: { key: 'PLAY_ORIGINAL_TEXT', name: '播放原文' },
  3: { key: 'WAIT_TIME', name: '等待时间' },
  4: { key: 'RECORDED_ANSWER', name: '录音作答' },
  5: { key: 'PLAY_VIDEO', name: '播放视频' },
  6: { key: 'CHOICE_QUESTIONS_AUDIO', name: '选择题音频' },
  7: { key: 'TEXT_READING_ALOUD', name: '课文朗读' },
  8: { key: 'MIXED_CHOICE_QUESTIONS_MULTI_AUDIO', name: '混合选择题多音频' },
  9: { key: 'OPTIONS_TICK', name: '选项勾选' },
  10: { key: 'PLAY_SHORT_TEXT', name: '播放短文' }
}
// 题型类型枚举
export const showTypeEnum = {
  0: '视频',
  1: '图片',
  2: '文本',
  3: '单音频选择题',
  4: '多音频选择题',
  5: '填空题'
}
// 评测类型枚举
export const oralTypeEnum = {
  0: { key: 'unknow', name: '请选择', id: 0 },
  1: { key: 'oralTypeWord', name: '单词', id: 1 },
  2: { key: 'oralTypeSentence', name: '语句', id: 2 },
  3: { key: 'oralTypeParagraph', name: '段落', id: 3 },
  4: { key: 'oralTypeChoose', name: '选择题', id: 4 },
  5: { key: 'oralTypeEssayQuestion', name: '问答题', id: 5 },
  6: { key: 'oralTypePicture', name: '看图作文', id: 6 },
  7: { key: 'oralTypeChineseWord', name: '中文单词', id: 7 },
  8: { key: 'oralTypeChineseSentence', name: '中文句子', id: 8 },
  9: { key: 'oralTypeChinesePcha', name: '中文有限分支识别评测', id: 9 },
  10: { key: 'oralTypeChinesePred', name: '中文段落', id: 10 },
  11: { key: 'oralTypeEnglishPcha', name: '英文有限分支识别评测', id: 11 },
  12: { key: 'oralTypeAlpha', name: '字母评测', id: 12 },
  13: { key: 'oralTypeRec', name: '自由识别', id: 13 },
  14: { key: 'oralTypePcha', name: '句子选读', id: 14 },
  15: { key: 'oralTypeRetell', name: '故事复述', id: 15 },
  16: { key: 'oralTypePche', name: '扩展选择', id: 16 },
  100: { key: 'oralTypeTranslateQuestion', name: '翻译', id: 100 }
}
// 音素枚举
export const PhonemeContrastEnum = {
  'ih': 'ɪ',
  'ax': 'ə',
  'oo': 'ɒ',
  'uh': 'ʊ',
  'ah': 'ʌ',
  'eh': 'e',
  'ae': 'æ',
  'iy': 'iː',
  'er': 'ɜː',
  'ao': 'ɔː',
  'uw': 'uː',
  'y uw': 'ju:',
  'aa': 'ɑː',
  'ey': 'eɪ',
  'ay': 'aɪ',
  'oy': 'ɔɪ',
  'aw': 'aʊ',
  'ow': 'əʊ',
  'ir': 'ɪə',
  'ar': 'eə',
  'ur': 'ʊə',
  'p': 'p',
  'b': 'b',
  't': 't',
  'd': 'd',
  'k': 'k',
  'g': 'g',
  'f': 'f',
  'v': 'v',
  's': 's',
  'th': 'θ',
  'dh': 'ð',
  'z': 'z',
  'sh': 'ʃ',
  'zh': 'ʒ',
  'ch': 'tʃ',
  'jh': 'dʒ',
  'hh': 'h',
  'm': 'm',
  'n': 'n',
  'nb': 'nb',
  'ng': 'ŋ',
  'l': 'l',
  'r': 'r',
  'y': 'j',
  'w': 'w',
  'dr': 'dr',
  'dz': 'dz',
  'tr': 'tr',
  'ts': 'ts'
}
