<template>
  <div id="my-title">
    <div class="title">{{ title }}</div>
    <div v-if="$route.name === 'Home'" class="school">
      <img :src="require('@/assets/bar/school-left.png')" class="bar-school-icon school-left" alt="">
      <span><img :src="require('@/assets/bar/school-icon.png')" class="school-icon" alt="">{{ schoolName }}</span>
      <img :src="require('@/assets/bar/school-right.png')" class="bar-school-icon school-right" alt="">
    </div>
    <div class="operation">
      <div class="user">
        <div v-if="token" class="bar-hover" @click="changeShow">
          <img :src="require('@/assets/bar/user.png')" class="bar-icon" alt="">
        </div>
        <div v-show="showClass" ref="classList" class="class-list" :tabindex="1" @blur="divBlur">
          <div class="user-info">
            <span>你好，<span>{{ userName }}</span>！</span>
            <span>当前科目：英语</span>
          </div>

          <div class="module-content">
            <div class="edit-password mr-15" @click="editPassword">
              <img :src="require('@/assets/common/change_password.png')" class="change-password-icon" alt="">
              <span>修改密码</span>
            </div>
            <div class="logout" @click="logout">
              <img :src="require('@/assets/common/login_out.png')" class="login-out-icon" alt="">
              <span class="text">退出登录</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bar-hover">
        <img :src="require('@/assets/bar/min.png')" class="bar-icon" alt="" @click="minimizeWin">
      </div>
      <div class="bar-hover">
        <img v-if="!maximized" :src="require('@/assets/bar/max.png')" class="bar-icon" alt="" @click="maximizeWin">
        <img v-if="maximized" :src="require('@/assets/bar/recovery.png')" class="bar-icon" alt="" @click="maximizeWin">
      </div>
      <div class="bar-hover">
        <img :src="require('@/assets/bar/close.png')" class="bar-icon" alt="" @click="closeWin">
      </div>
    </div>
    <EditPassword ref="editPassword" />
  </div>
</template>

<script>
import { title } from '@/utils/dictionary'
import EditPassword from '@/components/common/EditPassword'

export default {
  // 自定义标题栏
  name: 'MyTitle',
  components: { EditPassword },
  data() {
    return {
      title,
      showClass: false
    }
  },
  computed: {
    // 最大化状态
    maximized() {
      return this.$store.getters.maximized
    },
    // 最小化状态
    minimized() {
      return this.$store.getters.maximized
    },
    token() {
      return this.$store.getters.token
    },
    // 最小化状态
    userName() {
      return this.$store.getters.userName
    },
    // 最小化状态
    schoolName() {
      return this.$store.getters.userInfo ? this.$store.getters.userInfo.schoolName : ''
    }
  },
  created() {
    // 当程序在 electron 里时，接收electron 主进程发送的函数
    if (this.$store.getters.inElectron) {
      // 渲染进程接收主进程（background.js）传递函数
      // 是否最大化窗口
      window.electron.onCreateBrowserView('mainWinMax', (_, status) => {
        this.$store.commit('window/SMART_TEACHING_SYSTEM_MAXIMIZED', status)
      })
      // 是否最小化窗口
      window.electron.onCreateBrowserView('mainWinMin', (_, status) => {
        this.$store.commit('window/SMART_TEACHING_SYSTEM_MINIMIZED', status)
      })
      // 调整窗口大小后
      window.electron.onCreateBrowserView('resized', (_, data) => {
        this.$store.commit('window/SMART_TEACHING_SYSTEM_WINDOW_SIZE', data)
      })
    }
  },
  methods: {
    // 弹框失去焦点
    divBlur() {
      this.showClass = false
    },
    changeShow() {
      this.showClass = true
      this.$nextTick(() => {
        this.$refs.classList.focus()
      })
    },
    // 通知主进程我要进行窗口最小化操作
    minimizeWin() {
      window.electron.ipcRenderer.send('window-min')
    },
    // 通知主进程我要进行最大化 或 还原
    maximizeWin() {
      window.electron.ipcRenderer.send('window-max')
      this.$bus.$emit('reSizeScreen', this.maximized)
    },
    // 通知主进程我要关闭
    closeWin() {
      const that = this
      const { meta } = this.$route
      if (meta.isHome) {
        this.$confirm({
          title: '温馨提示',
          content: '是否确认关闭系统?',
          okText: '确认',
          okType: 'danger',
          cancelText: '取消',
          centered: true,
          onOk() {
            window.electron.ipcRenderer.send('window-close')
          },
          onCancel() {
          }
        })
      } else {
        if (meta.answer) {
          that.$confirm({
            title: '是否要退出做题?',
            maskClosable: true,
            okText: '退出',
            cancelText: '返回',
            centered: true,
            onOk() {
              that.$router.go(-1)
            }
          })
        } else if (meta.isPk) {
          that.$confirm({
            title: '是否要退出PK?',
            maskClosable: true,
            okText: '退出',
            cancelText: '返回',
            centered: true,
            onOk() {
              sessionStorage.setItem('pkSelectedWordIds', JSON.stringify({}))
              sessionStorage.setItem('pkSelectedStudent', JSON.stringify([]))
              sessionStorage.setItem('openDialog', JSON.stringify(false))
              that.$router.go(-1)
            }
          })
        } else {
          this.$router.go(-1)
        }
      }
    },
    // 修改密码
    editPassword() {
      this.$refs.editPassword.changeVisible(true)
    },
    // 退出登录
    async logout() {
      this.showClass = false
      await this.$store.dispatch('user/logout')
      // 清除临时缓存的单词作答记录
      sessionStorage.removeItem('wordAnswerStorage')
      sessionStorage.removeItem('pkSelectedWordIds')
      sessionStorage.removeItem('pkSelectedStudent')
      this.$router.push({ path: '/login' }).then()
    }
  }

}
</script>

<style lang="scss" scoped>
#my-title {
  width: 100%;
  height: 40px;
  background-color: #FFFFFF;
  -webkit-app-region: drag;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EEEEEE;

  .title {
    -webkit-app-region: drag;
    margin-left: 20px;
    color: #101010;
    font-size: 20px;
    line-height: 40px;
    font-weight: bold;
  }
  .school{
    margin-top: 10px;
    line-height: 28px;
    height: 50px;
    color: #333333;
    font-size: 20px;
    padding: 11px 40px;
    border-radius: 0 0 20px 20px;
    background: linear-gradient(90deg, #C1DBFF 0%, #FFE5C8 100%);
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    .bar-school-icon{
      width: 50px;
      height: 40px;
      position: absolute;
      top: 0;
      &.school-left{
        left: -50px;
      }
      &.school-right{
        right: -50px;
      }
    }
    .school-icon{
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    -webkit-app-region: no-drag;

    .bar-icon {
      width: 20px;
      height: 20px;
    }

    .user {
      position: relative;
      height: 100%;
      z-index: 2000;

      .class-list {
        position: absolute;
        top: 40px;
        right: -80px;
        width: 410px;
        font-size: 17px;
        line-height: 24px;
        color: #333333;
        z-index: 2;
        background: #FFFFFF;
        border-radius: 0 0 32px 32px;
        box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.3);
        outline: none;
        .user-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0 14px;
          margin: 0 28px;
          border-bottom: 1px solid #E5E5E5;

          span {
            font-size: 17px;
            font-weight: 400;
            color: #333333;
          }
        }

        .class-content {
          min-height: 60px;
          max-height: 350px;
          overflow-y: auto;
          padding: 0 28px;

          .class-item {
            margin-top: 10px;
            background: #F6F6F6;
            height: 50px;
            padding: 13px 14px;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &.active {
              color: #1976FF;
            }

            &:hover {
              background: #F1F1F1;
            }

            .item-id {
              font-size: 17px;
              line-height: 20px;
            }

            .select-img {
              width: 20px;
              height: 20px;
            }
          }
        }

        .module-content {
          margin: 20px 0 28px;
          padding: 0 28px;
          display: flex;
          //justify-content: space-between;
          justify-content: center;
          align-items: center;

          .edit-password {
            width: 170px;
            height: 76px;
            background: rgba(25, 118, 255, 0.1);
            border-radius: 12px;
            font-size: 17px;
            font-weight: 400;
            color: #1976FF;

            .change-password-icon {
              width: 72px;
              height: 76px;
            }

            &:hover,
            &:active {
              background: rgba(25, 118, 255, 0.2);
            }
          }

          .logout {
            width: 170px;
            //width: 100%;
            height: 76px;
            background: rgba(255, 60, 48, 0.1);
            border-radius: 12px;
            font-size: 17px;
            font-weight: 400;
            color: #FF3C30;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 20px;

            .login-out-icon {
              width: 72px;
              height: 76px;
            }

            .text{
              //margin-left: 10px;
            }

            .right-arrow {
              width: 6px;
              height: 11px;
            }

            &:hover,
            &:active {
              background: rgba(255, 60, 48, 0.2);
            }
          }
        }
      }
    }
  }
}
</style>
