<template>
  <a-modal
    :title="title"
    :visible="visible"
    :footer="null"
    :destroy-on-close="true"
    :mask-closable="false"
    :width="334"
    :centered="true"
    @cancel="handleCancel"
  >
    <img slot="closeIcon" :src="require('@/assets/common/close.png')" alt="" class="close-icon">
    <a-input v-model="activationCode" placeholder="请输入激活码" class="modal-input mb-20">
      <img slot="suffix" class="modal-close" :src="require('@/assets/common/solid_close_grey.png')" alt="" @click="activationCode = ''">
    </a-input>
    <div class="modal-footer">
      <a-button key="submit" :class="['modal-btn', 'ok-btn', activationCode ? '' : 'without']" type="primary" :loading="confirmLoading" :disabled="!activationCode || confirmLoading" @click="handleOk">确定</a-button>
      <a-button key="back" class="modal-btn cancel-btn" :disabled="confirmLoading" @click="handleCancel">取消</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'ActivationCode',
  data() {
    return {
      title: '请输入激活码',
      visible: false,
      confirmLoading: false,
      activationCode: ''
    }
  },
  methods: {
    changeVisible(bol) {
      this.visible = bol
    },
    handleOk() {
      this.confirmLoading = true
      setTimeout(() => {
        this.confirmLoading = false
        this.$message.success('激活成功')
        this.$emit('activation')
        this.changeVisible(false)
      }, 1000)
    },
    handleCancel() {
      if (this.confirmLoading) return
      this.changeVisible(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-close{
  margin-left: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
:deep(.ant-modal) {
  .ant-modal-content{
    border-radius: 16px;
  }
  .ant-modal-close{
    .ant-modal-close-x{
      .close-icon{
        width: 20px;
        height: 20px;
      }
    }
  }
  .ant-modal-header{
    padding: 14px 28px;
    border-radius: 16px 16px 0 0;
    .ant-modal-title{
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      color: #333333;
    }
  }
  .ant-modal-body{
    padding: 20px 28px;
    .modal-input{
      width: 278px;
      height: 50px;
    }
    .ant-input-affix-wrapper{
      .ant-input{
        padding-right: 40px;
        padding-left: 20px;
        height: 50px;
        color: #333333;
        font-size: 16px;
        line-height: 22px;
        border-radius: 8px;
        &::placeholder{
          color: #999999;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    .modal-footer{
      .modal-btn{
        width: 125px;
        height: 40px;
        border-radius: 40px;
        border: none;
        font-size: 15px;
        line-height: 21px;
        &+.modal-btn{
          margin-left: 28px;
        }
        &.ok-btn{
          color: #FFFFFF;
          background: linear-gradient(-90deg, #66ADFF 0%, #1976FF 100%);
          &.without{
            background: linear-gradient(-90deg, #C0E0FB 0%, #C0E0FB 100%);
            &:hover{
              background: linear-gradient(-90deg, #C0E0FB 0%, #C0E0FB 100%);
            }
          }
          &:hover{
            background: linear-gradient(-90deg, #0565F3 0%, #0565F3 100%);
          }
        }
        &.cancel-btn{
          color: #666666;
          background: #F6F6F6;
          &:hover{
            background: #EFEFEF;
          }
        }
      }
    }
  }
}
</style>
