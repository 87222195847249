<template>
  <div
    ref="Sidebar"
    :class="['sidebar', direction, unfold ? '' : 'hide']"
    :style="{width:leftContentWidth+'px', transition: 'width '+ transitionTime +'s'}"
  >
    <slot name="content" />
    <Unfold class="unfold" :direction="direction" :unfold.sync="unfold" />
    <div v-if="unfold && !banDrag" class="drag-border" @mousedown="dragAround" />
    <div v-else class="ban-drag" />
    <div :class="['shadow', direction]">
      <img v-if="direction === 'left'" class="img-shadow" :src="require('@/assets/common/content-left.png')" alt="">
      <img v-if="direction === 'right'" class="img-shadow" :src="require('@/assets/common/content-right.png')" alt="">
    </div>
  </div>
</template>

<script>
import Unfold from '@/components/common/Unfold'

export default {
  // 侧边栏组件
  name: 'Sidebar',
  components: {
    Unfold
  },
  props: {
    // 方位 -- 侧边栏的方位（目前只有左边和右边）
    direction: {
      type: String,
      default: () => 'left'
    },
    // 禁止/允许拖动 -- true：禁止；false：允许
    banDrag: {
      type: Boolean,
      default: () => true
    },
    defaultWidth: {
      type: [String, Number],
      default: () => 300
    }
  },
  data() {
    return {
      leftUnfold: true,
      rightUnfold: true,
      unfold: true,
      leftContentWidth: '',
      // 动画过渡时间
      transitionTime: 1
    }
  },
  watch: {
    unfold(val) {
      this.$emit('isFold', val)
    }
  },
  created() {
    this.leftContentWidth = this.defaultWidth
  },
  methods: {
    dragAround(e) {
      if (this.banDrag) return
      // 获取鼠标的初始位置
      const initialClientX = e.clientX
      // 获取侧边栏初始的宽度
      const clientWidth = this.$refs.Sidebar.clientWidth
      // 鼠标按下并移动的事件
      document.onmousemove = (e) => {
        this.transitionTime = 0
        // 计算鼠标向右移动了多少距离（为负数时代表向左移动）
        const width = e.clientX - initialClientX
        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        if (this.direction === 'left') {
          // 如果是左侧侧边栏，则在原宽度上加上移动的距离
          this.leftContentWidth = clientWidth + width
        } else if (this.direction === 'right') {
          // 如果是右侧侧边栏，则在原宽度上减去移动的距离
          this.leftContentWidth = clientWidth - width
        }
      }
      // 鼠标移动结束的事件
      document.onmouseup = () => {
        this.transitionTime = 1
        // 如果移动结束时侧边栏宽度小于60，将侧边栏宽度恢复为移动前的宽度，并将侧边栏收起
        if (this.leftContentWidth <= 60) {
          this.leftContentWidth = clientWidth
          this.unfold = false
        }
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  width: 300px;
  height: 100%;
  position: relative;
  background-color: #FFFFFF;
  //transition:width 0s;
  //-moz-transition:width 1s; /* Firefox 4 */
  //-webkit-transition:width 1s; /* Safari and Chrome */
  //-o-transition:width 1s; /* Opera */
  &.left {
    .drag-border {
      right: -5px;
      &:before{
        left: 5px;
      }
      &:hover:before {
        left: 5px;
      }

      &.ban-drag {
        border-left: 1px solid #F1F1F1;
      }
    }

    .ban-drag {
      right: -1px;
    }
  }

  &.right {
    .drag-border {
      left: -5px;

      &:before{
        right: 6px;
      }
      &:hover:before {
        right: 6px;
      }
    }

    .ban-drag {
      left: -1px;
    }
  }

  &.hide {
    width: 0 !important;
  }

  .drag-border {
    width: 11px;
    height: 100%;
    position: absolute;
    top: 0;
    cursor: w-resize;
    user-select: none;
    //z-index: 1;
    &:before {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
    }

    &:hover:before {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      background: linear-gradient(180deg, #1976ff00 0%, #1976FF 50%, #1976ff00 100%);
    }
  }

  .ban-drag {
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    user-select: none;
    z-index: 1;
    background-color: #F1F1F1;
  }
  .shadow{
    height: 100%;
    width: 40px;
    position: absolute;
    top: 0;
    .img-shadow{
      width: 100%;
      height: 100%;
    }
    &.left{
      right: -40px;
    }
    &.right{
      left: -41px;
    }
    //background: red;
  }
}
</style>
