<template>
  <div id="app">
    <MyTitle />
    <router-view :class="['container', $route.name === 'Home' ? 'home' : '']" :style="{fontSize:size +'px'}" />
  </div>
</template>
<script>
// import { token } from '@/utils/mockData'

export default {
  name: 'App',
  data() {
    return {}
  },
  computed: {
    size() {
      return Number(this.$store.getters.size)
    }
  },
  created() {
    const inElectron = !!(window && window.electron)
    // // TODO 临时使用内容平台token,记得更新token
    // this.$store.commit('user/SMART_TEACHING_SYSTEM_TOKEN', token)
    this.$store.commit('app/SET_IN_ELECTRON', inElectron)
  }
}
</script>

<style lang="scss">
// 点名淡入淡出
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(.8, -0.48, 1, 1);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateZ(10px) translateY(30px);
  opacity: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 1px solid #333333;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
}

html, body, div {
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  // 头部40 底部64
  height: calc(100vh - 106px);
  position: relative;
  overflow: auto;
  &.home{
    // 头部40
    height: calc(100vh - 42px);
  }

  .footer {
    position: fixed;
    bottom: 1px;
    left: 1px;
    z-index: 99;
  }
}
// 滚动条整体粗细样式
::-webkit-scrollbar {
  // 高宽分别对应横竖滚动条的尺寸
  width: 16px;
  height: 16px;
}

// 滚动条里面滑块
::-webkit-scrollbar-thumb {
  border-radius: 8px !important;
  // 通过设置边框达到滑块在轨道中间的效果
  border: 3px solid #FFFFFF;
  /* 颜色 */
  background: #E5E5E5 !important;
}

// 滚动条轨道
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* 标签样式 */
xmp {
  white-space: normal;
  margin: 0;
}

blockquote {
  display: block;
  text-indent: 2em;
  margin: 0;
}

b {
  font-weight: bold;
}

c {
  display: block;
  text-align: center;
}

l {
  display: block;
  text-align: left;
}

r {
  display: block;
  text-align: right;
}

u {
  text-decoration: underline;
}

u2 {
  border-bottom: 2px double black;
}
.t-content {
  t {
    background-color: #FC683A;
    height: 21px;
    line-height: 21px;
    padding: 0 20px;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
  }

  .active {
    background-color: #fff;
    border: 1px solid #C8C8C8;
    color: #FF7C53;
  }

  .answer-text {
    display: inline-block;
    background: #FFFFFF;
    height: 21px;
    padding: 0 15px;
    border: 1px solid #1DC060;
    border-radius: 4px;
    text-align: center;
    color: #1DC060;
    font-size: 12px;
    white-space: nowrap;
  }

  .false {
    background-color: #fff;
    color: #FF3C30;
    border: 1px solid #FF3C30;
  }

  .noAnswer {
    background-color: #fff;
    color: #309AF2;
    border: 1px solid #309AF2;
  }
}
.none {
  display: none;
}

.bg-f6 {
  background-color: #f6f6f6;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.col-1DC060  {
  color: #1DC060 !important;
}
.col-FFA516  {
  color: #FFA516 !important;
}
.col-FF3C30  {
  color: #FF3C30 !important;
}

.pre-line {
  white-space: pre-line;
}

.pointer {
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.repeat-txt {
  display: inline-block;
  background-color: #FF9756;
  color: #FF9756;
}

.repeat-txt::selection {
  color: transparent;
}
.fullscreen-modal{
  top: 41px !important;
}
</style>
